import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
const url = 'https://www.trener.spartaclub.net/api/';
import router from './../router/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    users: {
      buyedList: undefined,
      logged: true,
      good: false,
      user: {
        packId: 0,
        avatarUrl: './../../assets/img/profile.svg',
        isBuyed: false
      }
    },
    pass: '',
    mass: {
      title:'Обрати стать',
      girl: {
        title: 'Проблемы с сердцем',
        type: 0,
        mass: {
          title: 'Проблемы с опорно двигательным аппаратом',
          type: 0,
          mass: {
            title: 'Проблемы с эндокринной системой',
            type: 0,
            massResult: {
              titleOne: 'Худая',
              titleTwo: 'Средняя фигура',
              titleThree: 'Есть лишний вес',
              type: 0,
              result:[
                'tonus',
                'pohudenia',
              ]
            }
          }
        }
      },
      men: {
        title: 'Проблемы с сердцем',
        type: 0,
        mass: {
          title: 'Проблемы с опорно двигательным аппаратом',
          type: 0,
          mass: {
            title: 'Проблемы с эндокринной системой',
            type: 0,
            massResult: {
              titleOne: 'Худой',
              titleTwo: 'Средняя фигура',
              titleThree: 'Есть лишний вес',
              type: 0,
              result:[
                'tonus',
                'pohudenia',
                'nabor'
              ]
            }
          }
        }
      }
    },

    reg: {
      gender: 0,
      bodyType: 0,
      packId: 0,
      weight: 's'
    },
    oplata: undefined
  },
  actions: {
    loginAccountActions: ({commit}, data) => {
      axios.post(url + "internal/loginInAccount?login=" + data.login + "&password=" + data.password, {
        headers: {'Content-Type': 'application/json'}
      }).then(response => {
              if(response.data.isLogged) {
                router.push({name: 'profile'})}
              })
          .catch(function (error) {
            console.log(error);
            router.push({ name: 'login' })
          });
    },
    logoutActions: async () => {
      await axios.post(url + 'internal/logoutFromAccount', {
        headers: {'Content-Type': 'application/json'}
      }).then(response => response)
          .then(router.push({name: 'pageHome' }))
          .catch(function (error) {
            console.log(error);
          });
    },
    getSessionInfo: async ({commit: commit}) => {
      await axios.post(url + 'internal/getSessionInfo', {
        headers: {'Content-Type': 'application/json'}
      }).then(response => response)
          .then(res => {
            console.log(res.data)
            commit('SET_USER', res.data)
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getNewPassword: async ({commit}) => {
      await axios.get('https://random.justyy.workers.dev/api/random/?cached&n=14', {
        headers: {'Content-Type': 'application/json'}
      }).then(response => response)
          .then(res => {
            commit('SET_PASS', res.data.replace('"', ''))
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getRegister: async ({commit}, data) => {
      await axios.post(url + 'internal/registerAccount', {
        firstName: data.firstName,
        lastName: data.lastName,
        phoneNumber: data.phoneNumber,
        email: data.email,
        password: data.password,
        gender: data.gender,
        bodyType: data.bodyType,
        packId: data.packId,
        city: data.city,
        weight: data.weight
      }, {
        headers: {'Content-Type': 'application/json'}
      })
    },
    getOplata: async ({commit}, data) => {
      await axios.post(url + 'internal/getPaymentForm', {
        amount: data.oplata,
        currency: "UAH",
        name: "trenerovka",
        city: data.city,
        packId: data.packId,
        bodyType: data.bodyType
      }, {
        headers: {'Content-Type': 'application/json'}
      }).then(response => {
        commit('oplataMutations', response.data.form.replace(/\n/g, ''))
      }).then(router.push({name: 'linkpay'}))
    },
    regActions: ({commit}, data) => {
      commit('regMutations', data)
    },
    postImg: async ({commit}, data) => {
      await axios.post(url + 'internal/changeAvatar', {
        avatar: data,
      }, {
        headers: {'Content-Type': 'application/json'}
      })
    },
  },
  mutations: {
    SET_USER: (state, data) => {
      return state.users = data
    },
    SET_PASS: (state, data) => {
      return state.pass = data
    },
    regMutations: (state, data) => {
      return state.reg = data
    },
    oplataMutations: (state, data) => {
      console.log(data)
      return state.oplata = data
    }
  },
  getters: {
    getUsers: state => {
      return state.users
    },
    getQuiz: state => {
      return state.mass
    },
    getPass: state => {
      return state.pass
    },
    regGetters: state => {
      return state.reg
    },
    oplataGetters: state => {
      return state.oplata
    },
  }
})
