<template>
  <div class="simple-modal">
    <div class="simple-modal-backdrop">
      <div class="simple-modal-container">
        <div class="simple-modal-content">
          <div class="simple-modal-border">
            <header class="simple-modal-header">
              {{this.$attrs.title}}
            </header>
            <section class="simple-modal-text">
              {{this.$attrs.text}}
            </section>
            <section class="simple-modal-context">
              <div v-for="index in this.$attrs.context"> <!-- будет идти как масив -->
                <p>{{index.title}}</p>
                {{index.text}}
              </div>
            </section>
            <section class="simple-modal-body">
              <div v-for="index in this.$attrs.body"> <!-- будет идти как масив -->
                <p>{{index.title}}</p>
                {{index.text}}
              </div>
            </section>
            <footer class="simple-modal-footer">
              <button type="button"
                      @click="closeModal">
                Закрити деталі
              </button>
            </footer>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.simple-modal {
  &-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    transition: opacity 0.3s ease;
    z-index: 9999;
  }
  &-container {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: auto;
    margin: 16px;
  }
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 500px;
    margin: 1.75rem auto;
    padding: 20px;
    border-radius: 5px;
    color: #000;
    background-color: rgba(#0060831A, 0.6);
    transform: translate(0, 0);
    transition: all 0.3s ease;
    box-sizing: border-box;
  }
  &-border {
    border: 3px solid rgba(#49A7C9, 0.6);
    box-sizing: border-box;
    border-radius: 25px;

    background-color: rgba(#0060831A, 0.2);

    padding:10px;
  }
  &-header {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 29px;
    text-align: center;

    color: #F8F8F8;

  }
  &-context{
    display:flex;
    justify-content:center;
    margin-top:20px;
    div{
      margin:0 auto;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      text-align: center;
      p{
        color: #FFFFFF;
      }
      color: #49A7C9;
    }
  }
  &-body{
    display:flex;
    justify-content:center;
    margin-top:20px;
    div{
      margin:0 auto;
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 23px;
      text-align: center;

      color: #49A7C9;
    }
  }
  &-footer{
    margin-top:20px;
    button{
      display:flex;
      justify-items:center;
      justify-content:center;
      margin: 0 auto;

      color: #49A7C9;

      border:1px solid #49A7C9;

      background-color:rgba(#49A7C9, 0.0);

      padding:10px;

      border-radius:15px;

      font-size:18px;

      cursor:pointer;
    }
  }
}
</style>
