<template>
  <div>
    <div style="display:flex; align-items:center; align-self:center; align-content:center; margin-top: 30px;">
      <div class="blurBlock">
        <div class="blurBlockPadding">
          <div class="blurBlockPaddingText profileCenter">
            <div style="display:flex; flex-wrap:wrap; justify-content:center">
              <div class="profileBlockImg">
                <div>
                  <img style="width: 200px; border-radius:110px" :src="getUsers.avatar" :error='`<svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="100" cy="100" r="100" fill="#C4C4C4"/>
<circle cx="100" cy="100" r="100" fill="url(#paint0_linear_344:2)" fill-opacity="0.2"/>
<path d="M100.499 98.1147C119.356 98.1147 134.644 79.9568 134.644 57.5576C134.644 35.158 129.625 17 100.499 17C71.3737 17 66.3535 35.158 66.3535 57.5576C66.3535 79.9568 81.6414 98.1147 100.499 98.1147Z" fill="#DBE1E3"/>
<path d="M36.0066 160.042C36.0009 158.676 35.9952 159.657 36.0066 160.042V160.042Z" fill="#DBE1E3"/>
<path d="M164.99 161.108C165.008 160.734 164.997 158.514 164.99 161.108V161.108Z" fill="#DBE1E3"/>
<path d="M164.915 158.403C164.283 119.383 159.072 108.265 119.194 101.227C119.194 101.227 113.581 108.222 100.498 108.222C87.4139 108.222 81.7996 101.227 81.7996 101.227C42.3573 108.188 36.8293 119.142 36.1024 157.136C36.0428 160.238 36.0152 160.401 36.0045 160.041C36.0069 160.716 36.0098 161.964 36.0098 164.141C36.0098 164.141 45.5037 182.857 100.498 182.857C155.49 182.857 164.985 164.141 164.985 164.141C164.985 162.742 164.986 161.77 164.988 161.108C164.977 161.331 164.956 160.899 164.915 158.403Z" fill="#DBE1E3"/>
<defs>
<linearGradient id="paint0_linear_344:2" x1="100" y1="0" x2="100" y2="200" gradientUnits="userSpaceOnUse">
<stop stop-opacity="0"/>
<stop offset="1"/>
</linearGradient>
</defs>
</svg>`'>
                </div>
                <label style="font-size:14px; color:#49A7C9; border: 3px solid #49A7C9; cursor:pointer; line-height:50px; padding:10px 20px; border-radius: 40px;">
                  ЗАВАНТАЖИТИ ФОТО
                  <input type="file" id="file" ref="file-input" @change="previewFiles" style="display:none"/>
                </label>
              </div>
              <div class="profileBlockText">
                <div class="profileName" style="display: flex; align-content:center; align-self:center; align-items:center;">
                  {{getUsers.user.firstName + ' ' + getUsers.user.lastName}}
                </div>
                <button class="oneBlockButton profileButton" style="margin-bottom:60px" @click="logOut">
                  ВИЙТИ
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import axios from "axios";

export default {
  name: "profile",
  data() {
    return {
      fileimg: ''
    }
  },
  methods: {
    ...mapActions(['logoutActions', 'getSessionInfo', 'postImg']),
    logOut: async function () {
      await this.logoutActions()
      await this.getSessionInfo()
    },
    previewFiles(e) {
      const files = e.target.files[0];
      const formData = new FormData();
      formData.append('avatar', files);

      axios.post('https://www.trener.spartaclub.net/api/internal/changeAvatar', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },
  },
  computed: {
    ...mapGetters(['getUsers'])
  },
  async created() {
    await this.getSessionInfo()
    if(!this.getUsers.user.isBuyed){
      await this.$router.push({name: 'oplata'})
    }
  }
}
</script>

<style lang="scss" scoped>
.profileBlockImg{
  width:200px;
  margin-top:5vh;
  margin-bottom:10vh
}
.profileBlockText{
  display:flex;
  flex-direction:column;
  margin-top:10vh;
}
.profileButton{
  margin-bottom:30px;
  margin-top:14vh
}
@media screen and (max-width:1169px){
  .profileName{
    font-size:15px !important;
  }
  .profileButton{
    width:200px !important;
    margin-bottom:20px;
    margin-top:5vh
  }
  .profileBlockImg{
    margin-left:0 !important;
    margin-bottom:2vh !important;
  }
  .profileBlockText{
    margin-left:0 !important;
    margin-top:0 !important;
  }
}

</style>
