<template>
  <div class="blurBlock quizBlock">
    <div  v-if="vals === 'mangirl'" class="blurBlockPadding">
      <div class="blurBlockPaddingText">
        Обрати стать
      </div>
      <div class="quizBlockVopros">
        <div class="colorBlockQuiz">
          <div class="imgBlockQuiz">
            <img src="./../../../assets/img/men.png">
          </div>
          <div class="buttonBlockQuiz" @click="vals = 'man1'">
            Хлопець
          </div>
        </div>
        <div class="colorBlockQuiz">
          <div class="imgBlockQuiz">
            <img src="./../../../assets/img/girl.png">
          </div>
          <div class="buttonBlockQuiz" @click="vals = 'girl1'">
            Дівчина
          </div>
        </div>
      </div>
    </div>
    <div v-if="vals === 'girl1'" class="blurBlockPadding">
      <div class="blurBlockPaddingText quizBlockText">
        Чи є у вас тяжкі хвороби серцево-судинної системи?
      </div>
      <div class="quizBlockVopros">
        <router-link to="/result?vds=defG" class="quizBlockButton">
          ТАК
        </router-link>
        <div class="quizBlockButton"  @click="vals = 'girl2'">
          НІ
        </div>
      </div>
    </div>
    <div v-if="vals === 'girl2'" class="blurBlockPadding">
      <div class="blurBlockPaddingText quizBlockText">
        Чи є у вас тяжкі хвороби опорно-рухового апарата?
      </div>
      <div class="quizBlockVopros">
        <router-link to="/result?vds=defG" class="quizBlockButton">
          ТАК
        </router-link>
        <div class="quizBlockButton" @click="vals = 'girl3'">
          НІ
        </div>
      </div>
    </div>
    <div v-if="vals === 'girl3'" class="blurBlockPadding">
      <div class="blurBlockPaddingText quizBlockText">
        Чи є у вас тяжкі хвороби ендокринної системи?
      </div>
      <div class="quizBlockVopros">
        <router-link to="/result?vds=defG" class="quizBlockButton">
          ТАК
        </router-link>
        <div class="quizBlockButton" @click="vals = 'girl4'">
          НІ
        </div>
      </div>
    </div>
    <div v-if="vals === 'girl4'" class="blurBlockPadding">
      <div class="blurBlockPaddingText quizBlockText">
        Ваша статура
      </div>
      <div class="quizBlockVopros">
        <div @click="routLink({link: 'tonusG', mass: {gender: 1, bodyType: 0, packId: 10, weight: 's' }})" class="quizBlockButton">
          Худа
        </div>
        <div @click="routLink({link: 'tonusG', mass: {gender: 1, bodyType: 1, packId: 10, weight: 's' }})" class="quizBlockButton">
          Середня фігура
        </div>
        <div @click="vals = 'girl5l'" class="quizBlockButton">
          Є зайва вага
        </div>
      </div>
    </div>
    <div v-if="vals === 'girl5l'" class="blurBlockPadding">
      <div class="blurBlockPaddingText quizBlockText">
        Оберіть курс тренувань
      </div>
      <div class="quizBlockVopros">
        <div @click="routLink({link: 'tonusG', mass: {gender: 1, bodyType: 2, packId: 10, weight: 's' }})" class="quizBlockButton">
          ТОНУС
        </div>
        <div @click="routLink({link: 'shudnenaG', mass: {gender: 1, bodyType: 2, packId: 11, weight: 's' }})" class="quizBlockButton">
          СХУДНЕННЯ
        </div>
      </div>
    </div>

    <div v-if="vals === 'man1'" class="blurBlockPadding">
      <div class="blurBlockPaddingText quizBlockText">
        Чи є у вас тяжкі хвороби серцево-судинної системи?
      </div>
      <div class="quizBlockVopros">
        <router-link to="/result?vds=defM" class="quizBlockButton">
          ТАК
        </router-link>
        <div class="quizBlockButton" @click="vals = 'man2'">
          НІ
        </div>
      </div>
    </div>
    <div v-if="vals === 'man2'" class="blurBlockPadding">
      <div class="blurBlockPaddingText quizBlockText">
        Чи є у вас тяжкі хвороби опорно-рухового апарата?
      </div>
      <div class="quizBlockVopros">
        <router-link to="/result?vds=defM" class="quizBlockButton">
          ТАК
        </router-link>
        <div class="quizBlockButton" @click="vals = 'man3'">
          НІ
        </div>
      </div>
    </div>
    <div v-if="vals === 'man3'" class="blurBlockPadding">
      <div class="blurBlockPaddingText quizBlockText">
        Чи є у вас тяжкі хвороби ендокринної системи?
      </div>
      <div class="quizBlockVopros">
        <router-link to="/result?vds=defM" class="quizBlockButton">
          ТАК
        </router-link>
        <div class="quizBlockButton"  @click="vals = 'man4'">
          НІ
        </div>
      </div>
    </div>
    <div v-if="vals === 'man4'" class="blurBlockPadding">
      <div class="blurBlockPaddingText quizBlockText">
        Ваша статура
      </div>
      <div class="quizBlockVopros">
        <div @click="routLink({link: 'nabirM', mass: {gender: 0, bodyType: 0, packId: 0, weight: 's' }})" class="quizBlockButton">
          Худий
        </div>
        <div @click="vals = 'man5m'"  class="quizBlockButton">
          Середня фігура
        </div>
        <div @click="vals = 'man5l'"  class="quizBlockButton">
          Є зайва вага
        </div>
      </div>
    </div>
    <div v-if="vals === 'man5m'" class="blurBlockPadding">
      <div class="blurBlockPaddingText quizBlockText">
        Оберіть курс тренувань
      </div>
      <div class="quizBlockVopros">
        <div @click="routLink({link: 'nabirM', mass: {gender: 0, bodyType: 1, packId: 0, weight: 's' }})" class="quizBlockButton">
          НАБІР М’ЯЗОВОЇ МАСИ
        </div>
        <div @click="routLink({link: 'tonusM', mass: {gender: 0, bodyType: 1, packId: 1, weight: 's' }})" class="quizBlockButton">
          ТОНУС
        </div>
      </div>
    </div>
    <div v-if="vals === 'man5l'" class="blurBlockPadding">
      <div class="blurBlockPaddingText quizBlockText">
        Оберіть курс тренувань
      </div>
      <div class="quizBlockVopros">
        <div @click="routLink({link: 'tonusM', mass: {gender: 0, bodyType: 2, packId: 0, weight: 's' }})" class="quizBlockButton">
          ТОНУС
        </div>
        <div @click="routLink({link: 'shudnenaM', mass: {gender: 0, bodyType: 2, packId: 2, weight: 's' }})" class="quizBlockButton">
          СХУДНЕННЯ
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "index",
  data() {
    return{
      vals: 'mangirl'
    }
  },
  methods:{
    ...mapActions(['regActions']),
    async routLink(data) {
      await this.regActions(data.mass)
      await this.$router.push('/result?vds=' + data.link)
    }
  },
}
</script>

<style lang="scss" scoped>
.quizBlock{
  margin-top:50px;
}
.quizBlockVopros{
  display:flex;
  justify-content: space-around;
  align-items: center;
  margin:2vw 10vw 3vw;
  flex-wrap: wrap;

  div{
    margin-top:25px;
  }
}
.quizBlockText{
  font-size:50px !important;
  line-height:60px !important;
}
</style>
