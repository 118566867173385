<template>
  <div>
    <div v-for="(names, namesKey) in workoutMass" :key="namesKey">
      <div v-if="vds === namesKey" class="programCenter">
        <div class="program workoutProgram">
          <div class="programBorder">
            <div style="display:flex; align-content:center; align-items:center; align-self:center; margin-bottom:40px">
              <a :href="'/workouttrener?type=' + names.prev" class="prevNext">
                <svg width="27" height="45" viewBox="0 0 27 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M27 4.02273L22.9049 0L0 22.5L22.9049 45L27 40.9773L8.19023 22.5L27 4.02273Z" fill="#49A7C9"/>
                </svg>
              </a>
              <div class="programBlockTitle">
                {{names.title}}
              </div>
              <a :href="'/workouttrener?type=' + names.next" class="prevNext">
                <svg width="27" height="45" viewBox="0 0 27 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 4.02273L4.09512 0L27 22.5L4.09512 45L0 40.9773L18.8098 22.5L0 4.02273Z" fill="#49A7C9"/>
                </svg>
              </a>
            </div>
            <div v-for="index in names.mass" class="blockProgram">
              <div class="blockProgramTitle">
                <div>
                  Тренування “{{ index.title }}“
                </div>
                <div class="blockProgramContext">
                  {{ index.context }}
                </div>
                <div v-if="index.buttons" class="blockProgramButton"  @click="toggleModal({title: index.title, context: index.modal.context, body: index.modal.body})">
                  ДЕТАЛЬНІШЕ
                </div>
              </div>
              <div class="blockProgramImg">
                <iframe :src="index.video" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
            </div>
          </div>
        </div>
        <Modal
            v-show="isShowModal"
            @close="toggleModal"
            v-bind:title="dataModal.title"
            v-bind:context="dataModal.context"
            v-bind:body="dataModal.body"
        ></Modal>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "../comp/modal";
import router from "../../router";
import {mapGetters} from 'vuex'

export default {
  name: "workoutTrener",
  components: {Modal},
  data: () => ({
    isShowModal: false,
    showed: {
      one1: true,
      two1: false,
      three1: false
    },

    dataModal: {},

    vds: '',

    workoutMass: {
      nabirm1s: {
        title: 'Груди-тріцепс-плечі',
        mass: [
          {
            title: 'Розминка',
            context: '5-10 хв',
            video: 'https://www.youtube.com/embed/vk30szk1CDs?modestbranding=1',
            buttons: false,
          },
          {
            title: 'Скручування на підлозі лежачи з піднятими ногами',
            context: '',
            video: 'https://www.youtube.com/embed/r-BozOo-6L0?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Планка',
            context: '',
            video: 'https://www.youtube.com/embed/lRdcjyOGXmQ?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '40-90с',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Горизонтальний жим штанги',
            context: '',
            video: 'https://www.youtube.com/embed/Mjhpz1m2xEo?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: 'Пустий гриф'
                },
                {
                  title: '2,3,4 підходи',
                  text: '5 кг'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Жим в хаммері на груди',
            context: '',
            video: 'https://www.youtube.com/embed/sfta1I5jc1I?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: 'Без ваги'
                },
                {
                  title: '2,3,4 підходи',
                  text: '15 кг'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Брус’я в гравітроні або з резинкою',
            context: '',
            video: 'https://www.youtube.com/embed/7j1a-r3ZKcc?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: 'Ваша вага -10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: 'Ваша вага -20 кг'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розгибання рук в кроссовері однією рукою',
            context: '',
            video: 'https://www.youtube.com/embed/Kjk_CpSBenM?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '10 кг'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Махи лежачі на ногах',
            context: '',
            video: 'https://www.youtube.com/embed/log1WNqJssA?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '2 кг в руку'
                },
                {
                  title: '2,3,4 підходи',
                  text: '5 кг в руку'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Розтяжка грудей та тріцепса',
            context: '',
            video: 'https://www.youtube.com/embed/ic3rYCa5Rus?modestbranding=1',
            buttons: false,
            modal: {
              context: [],
              body: []
            }
          }],
        prev: 'nabirm3s',
        next: 'nabirm2s'
      }, //done
      nabirm2s: {
        title: 'Ноги-плечі',
        mass: [
          {
            title: 'Розминка',
            context: '5-10 хв',
            video: 'https://www.youtube.com/embed/vk30szk1CDs?modestbranding=1',
            buttons: false,
          },
          {
            title: 'Ікри',
            context: '',
            video: 'https://www.youtube.com/embed/J4DFwTK0ab4?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розгинання ніг в тренажері',
            context: '',
            video: 'https://www.youtube.com/embed/V9tDbk99-N8?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Згибання ніг в тренажері',
            context: '',
            video: 'https://www.youtube.com/embed/ekVIK3_hjCk?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Зведення ніг в тренажері',
            context: '',
            video: 'https://www.youtube.com/embed/DH3cpNkxay8?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розведення ніг в тренажері',
            context: '',
            video: 'https://www.youtube.com/embed/JZ5u5MRw8-M?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '30 кг'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Жим в хаммері на плечі',
            context: '',
            video: 'https://www.youtube.com/embed/H1SuI-6er_w?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: 'Без ваги'
                },
                {
                  title: '2,3,4 підходи',
                  text: '10 кг'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Підйом гантелей перед собою',
            context: '',
            video: 'https://www.youtube.com/embed/-xX7qElF0cM?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '2 кг на руку'
                },
                {
                  title: '2,3,4 підходи',
                  text: '5 кг на руку'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Розтяжка ніг та плечей',
            context: '',
            video: 'https://www.youtube.com/embed/2T6nYvIXlT8?modestbranding=1',
            buttons: false,
            modal: {
              context: [],
              body: []
            }
          }
        ],
        prev: 'nabirm1s',
        next: 'nabirm3s'
      }, //done
      nabirm3s: {
        title: 'Спина-біцепс',
        mass: [
          {
            title: 'Розминка',
            context: '5-10 хв',
            video: 'https://www.youtube.com/embed/vk30szk1CDs?modestbranding=1',
            buttons: false,
          },
          {
            title: 'Скручування на підлозі лежачи з піднятими ногами',
            context: '',
            video: 'https://www.youtube.com/embed/r-BozOo-6L0?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Планка',
            context: '',
            video: 'https://www.youtube.com/embed/lRdcjyOGXmQ?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '40-90с',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Гіперекстензія',
            context: '',
            video: 'https://www.youtube.com/embed/0koLScSf5x8?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: 'Виконуйте вправу із власною вагою',
                  text: ''
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Важільна тяга сидячи',
            context: '',
            video: 'https://www.youtube.com/embed/oG-dD-EJ4es?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Підтягування з гравітроном або резинкою',
            context: '',
            video: 'https://www.youtube.com/embed/yoAVzEfHGnI?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: 'Ваша вага -10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: 'Ваша вага -20 кг'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Біцепс стоячи в кроссовері',
            context: '',
            video: 'https://www.youtube.com/embed/-HjTmK4H-T8?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '15 кг'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Молоти в кроссовері з канатом',
            context: '',
            video: 'https://www.youtube.com/embed/ffLqdL5NeyE?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '15 кг'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Біцепс машина',
            context: '',
            video: 'https://www.youtube.com/embed/DSyVZL3vb2Q?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '10 кг'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Розтяжка спини та біцепса',
            context: '',
            video: 'https://www.youtube.com/embed/TRYuLOqx1uA?modestbranding=1',
            buttons: false,
            modal: {
              context: [],
              body: []
            }
          }
        ],
        prev: 'nabirm2s',
        next: 'nabirm1s'
      }, //done

      nabirm1m: {
        title: 'Груди-тріцепс-плечі',
        mass: [
          {
            title: 'Розминка',
            context: '5-10 хв',
            video: 'https://www.youtube.com/embed/vk30szk1CDs?modestbranding=1',
            buttons: false,
          },
          {
            title: 'Скручування на підлозі лежачи з піднятими ногами',
            context: '',
            video: 'https://www.youtube.com/embed/r-BozOo-6L0?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Планка',
            context: '',
            video: 'https://www.youtube.com/embed/lRdcjyOGXmQ?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '40-90с',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Горизонтальний жим штанги',
            context: '',
            video: 'https://www.youtube.com/embed/Mjhpz1m2xEo?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: 'Пустий гриф'
                },
                {
                  title: '2,3,4 підходи',
                  text: '10 кг'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Жим в хаммері на груди',
            context: '',
            video: 'https://www.youtube.com/embed/sfta1I5jc1I?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: 'Без ваги'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Брус’я в гравітроні або з резинкою',
            context: '',
            video: 'https://www.youtube.com/embed/7j1a-r3ZKcc?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: 'Ваша вага -10кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: 'Ваша вага -20 кг'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розгибання в кроссовері однією рукою',
            context: '',
            video: 'https://www.youtube.com/embed/Kjk_CpSBenM?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '10 кг'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Махи лежачі на ногах',
            context: '',
            video: 'https://www.youtube.com/embed/log1WNqJssA?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '2 кг на руку'
                },
                {
                  title: '2,3,4 підходи',
                  text: '5 кг на руку'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Розтяжка грудей та тріцепсу',
            context: '',
            video: 'https://www.youtube.com/embed/ic3rYCa5Rus?modestbranding=1',
            buttons: false,
            modal: {
              context: [],
              body: []
            }
          }
        ],
        prev: 'nabirm3m',
        next: 'nabirm2m'
      }, //done
      nabirm2m: {
        title: 'Ноги-плечі',
        mass: [
          {
            title: 'Розминка',
            context: '5-10 хв',
            video: 'https://www.youtube.com/embed/vk30szk1CDs?modestbranding=1',
            buttons: false,
          },
          {
            title: 'Ікри',
            context: '',
            video: 'https://www.youtube.com/embed/J4DFwTK0ab4?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розгинання ніг в тренажері',
            context: '',
            video: 'https://www.youtube.com/embed/V9tDbk99-N8?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '25 кг'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Згибання ніг в тренажері',
            context: '',
            video: 'https://www.youtube.com/embed/ekVIK3_hjCk?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '25 кг'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Зведення ніг в тренажері',
            context: '',
            video: 'https://www.youtube.com/embed/DH3cpNkxay8?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '25 кг'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розведення ніг в тренажері',
            context: '',
            video: 'https://www.youtube.com/embed/JZ5u5MRw8-M?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '15 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '40 кг'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Жим в хаммері на плечі',
            context: '',
            video: 'https://www.youtube.com/embed/H1SuI-6er_w?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: 'Без ваги'
                },
                {
                  title: '2,3,4 підходи',
                  text: '10 кг'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Підйом гантелей перед собою',
            context: '',
            video: 'https://www.youtube.com/embed/-xX7qElF0cM?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '2 кг на руку'
                },
                {
                  title: '2,3,4 підходи',
                  text: '7 кг на руку'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Розтяжка ніг та плечей',
            context: '',
            video: 'https://www.youtube.com/embed/2T6nYvIXlT8?modestbranding=1',
            buttons: false,
            modal: {
              context: [],
              body: []
            }
          }
        ],
        prev: 'nabirm1m',
        next: 'nabirm3m'
      }, //done
      nabirm3m: {
        title: 'Спина-біцепс',
        mass: [
          {
            title: 'Розминка',
            context: '5-10 хв',
            video: 'https://www.youtube.com/embed/vk30szk1CDs?modestbranding=1',
            buttons: false,
          },
          {
            title: 'Скручування на підлозі лежачи з піднятими ногами',
            context: '',
            video: 'https://www.youtube.com/embed/r-BozOo-6L0?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Планка',
            context: '',
            video: 'https://www.youtube.com/embed/lRdcjyOGXmQ?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '40-90с',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Гіперекстензія',
            context: '',
            video: 'https://www.youtube.com/embed/0koLScSf5x8?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: 'Виконуйте вправу із власною вагою',
                  text: ''
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Важільна тяга сидячи',
            context: '',
            video: 'https://www.youtube.com/embed/oG-dD-EJ4es?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Підтягування з гравітроном або з резинкою',
            context: '',
            video: 'https://www.youtube.com/embed/yoAVzEfHGnI?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: 'Ваша вага -10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: 'Ваша ваги -20 кг'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Біцепс стоячи в кроссовері',
            context: '',
            video: 'https://www.youtube.com/embed/-HjTmK4H-T8?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '15 кг'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Молоти в кроссовері з канатом',
            context: '',
            video: 'https://www.youtube.com/embed/ffLqdL5NeyE?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '15 кг'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Біцепс машина',
            context: '',
            video: 'https://www.youtube.com/embed/DSyVZL3vb2Q?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '10 кг'
                },
              ],
              body: [
                {
                  title: '10-12 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Розтяжка спини та біцепса',
            context: '',
            video: 'https://www.youtube.com/embed/TRYuLOqx1uA?modestbranding=1',
            buttons: false,
            modal: {
              context: [],
              body: []
            }
          }
        ],
        prev: 'nabirm2m',
        next: 'nabirm1m'
      }, //done

      tonusm1m: {
        title: 'Ноги-плечі',
        mass: [
          {
            title: 'Розминка',
            context: '5-10 хв',
            video: 'https://www.youtube.com/embed/vk30szk1CDs?modestbranding=1',
            buttons: false,
          },
          {
            title: 'Ікри',
            context: '',
            video: 'https://www.youtube.com/embed/J4DFwTK0ab4?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Згинання ніг в тренажері',
            context: '',
            video: 'https://www.youtube.com/embed/ekVIK3_hjCk?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розведення ніг в тренажері',
            context: '',
            video: 'https://www.youtube.com/embed/JZ5u5MRw8-M?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '15 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '40 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Сідничний міст на підлозі',
            context: '',
            video: 'https://www.youtube.com/embed/qtnr_Bqq2iE?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '10 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Жим платформи з вузькою постановкою ніг',
            context: '',
            video: 'https://www.youtube.com/embed/tKD0QG6yj88?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Жим гантелей над головою сидячи',
            context: '',
            video: 'https://www.youtube.com/embed/tvAF8xjnEK0?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '2 кг на руку'
                },
                {
                  title: '2,3,4 підходи',
                  text: '5 кг на руку'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розтяжка ніг та плечей',
            context: '',
            video: 'https://www.youtube.com/embed/2T6nYvIXlT8?modestbranding=1',
            buttons: false,
            modal: {
              context: [],
              body: []
            }
          },
        ],
        prev: 'tonusm3m',
        next: 'tonusm2m'
      }, //done VIDEO
      tonusm2m: {
        title: 'Спина',
        mass: [
          {
            title: 'Розминка',
            context: '5-10 хв',
            video: 'https://www.youtube.com/embed/vk30szk1CDs?modestbranding=1',
            buttons: false,
          },
          {
            title: 'Скручування на підлозі лежачи з піднятими ногами',
            context: '',
            video: 'https://www.youtube.com/embed/r-BozOo-6L0?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Планка',
            context: '',
            video: 'https://www.youtube.com/embed/lRdcjyOGXmQ?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '40-90с',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Гіперекстензія',
            context: '',
            video: 'https://www.youtube.com/embed/0koLScSf5x8?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: 'Виконуйте вправу із власною вагою',
                  text: ''
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Тяга верхнього блоку з вузькою ручкою',
            context: '',
            video: 'https://www.youtube.com/embed/N9gXdYi3ec8?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '30 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Тяга нижнього блоку з вузькою ручкою',
            context: '',
            video: 'https://www.youtube.com/embed/JCf3CREeLTE?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '30 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            },
          },
          {
            title: 'Важільна тяга',
            context: '',
            video: 'https://www.youtube.com/embed/oG-dD-EJ4es?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розтяжка спини',
            context: '',
            video: 'https://www.youtube.com/embed/KCsQk2ivXIM?modestbranding=1',
            buttons: false,
            modal: {
              context: [],
              body: []
            }
          }
        ],
        prev: 'tonusm1m',
        next: 'tonusm3m'
      }, //done VIDEO
      tonusm3m: {
        title: 'Груди-тріцепс-біцепс',
        mass: [
          {
            title: 'Розминка',
            context: '5-10 хв',
            video: 'https://www.youtube.com/embed/vk30szk1CDs?modestbranding=1',
            buttons: false,
          },
          {
            title: 'Скручування на підлозі лежачи з піднятими ногами',
            context: '',
            video: 'https://www.youtube.com/embed/r-BozOo-6L0?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Планка',
            context: '',
            video: 'https://www.youtube.com/embed/lRdcjyOGXmQ?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '40-90с',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Віджимання від підлоги на колінах',
            context: '',
            video: 'https://www.youtube.com/embed/4XLWULnFyBY?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Жим гантелей під нахилом 45 градусів на лавці',
            context: '',
            video: 'https://www.youtube.com/embed/G-jXkqoF09g?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг на руку'
                },
                {
                  title: '2,3,4 підходи',
                  text: '10 кг на руку'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Підйом гантелей на біцепс',
            context: '',
            video: 'https://www.youtube.com/embed/M-Opf3rAyU8?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '2 кг на руку'
                },
                {
                  title: '2,3,4 підходи',
                  text: '5 кг на руку'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Молотки з гантелями',
            context: '',
            video: 'https://www.youtube.com/embed/OGV4jnSjm9s?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг на руку'
                },
                {
                  title: '2,3,4 підходи',
                  text: '10 кг на руку'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Віджимання від лавки на тріцепс',
            context: '',
            video: 'https://www.youtube.com/embed/A4WQgwVge8Q?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: 'Виконуйте вправу із власною вагою',
                  text: '10-15 разів'
                },
              ],
              body: []
            }
          },
          {
            title: 'Розгинання рук з рівною ручкою',
            context: '',
            video: 'https://www.youtube.com/embed/qbZNqRmLofE?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '15 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розтяжка грудей та біцепса',
            context: '',
            video: 'https://www.youtube.com/embed/MgjTmjgWZdU?modestbranding=1',
            buttons: false,
            modal: {
              context: [],
              body: []
            }
          },
        ],
        prev: 'tonusm2m',
        next: 'tonusm1m'
      }, // done VIDEO

      tonusm1l: {
        title: 'Ноги-плечі',
        mass: [
          {
            title: 'Розминка',
            context: '5-10 хв',
            video: 'https://www.youtube.com/embed/vk30szk1CDs?modestbranding=1',
            buttons: false,
          },
          {
            title: 'Ікри',
            context: '',
            video: 'https://www.youtube.com/embed/J4DFwTK0ab4?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Згинання ніг в тренажері',
            context: '',
            video: 'https://www.youtube.com/embed/ekVIK3_hjCk?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '30 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розведення ніг в тренажері',
            context: '',
            video: 'https://www.youtube.com/embed/JZ5u5MRw8-M?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '15 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '40 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Сідничний міст на підлозі',
            context: '',
            video: 'https://www.youtube.com/embed/qtnr_Bqq2iE?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '10 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Жим платформи з вузькою постановкою ніг',
            context: '',
            video: 'https://www.youtube.com/embed/tKD0QG6yj88?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '30 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Жим гантелей над головою сидячи',
            context: '',
            video: 'https://www.youtube.com/embed/tvAF8xjnEK0?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '2 кг на руку'
                },
                {
                  title: '2,3,4 підходи',
                  text: '5 кг на руку'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розтяжка ніг та плечей',
            context: '',
            video: 'https://www.youtube.com/embed/2T6nYvIXlT8?modestbranding=1',
            buttons: false,
            modal: {
              context: [],
              body: []
            }
          },
        ],
        prev: 'tonusm3l',
        next: 'tonusm2l'
      }, //done VIDEO
      tonusm2l: {
        title: 'Спина',
        mass: [
          {
            title: 'Розминка',
            context: '5-10 хв',
            video: 'https://www.youtube.com/embed/vk30szk1CDs?modestbranding=1',
            buttons: false,
          },
          {
            title: 'Скручування на підлозі лежачи з піднятими ногами',
            context: '',
            video: 'https://www.youtube.com/embed/r-BozOo-6L0?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Планка',
            context: '',
            video: 'https://www.youtube.com/embed/lRdcjyOGXmQ?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '40-90с',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Гіперекстензія',
            context: '',
            video: 'https://www.youtube.com/embed/0koLScSf5x8?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: 'Виконуйте вправу із власною вагою',
                  text: ''
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Тяга верхнього блоку з вузькою ручкою',
            context: '',
            video: 'https://www.youtube.com/embed/N9gXdYi3ec8?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '30 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Тяга нижнього блоку з вузькою ручкою',
            context: '',
            video: 'https://www.youtube.com/embed/JCf3CREeLTE?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '30 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Важільна тяга',
            context: '',
            video: 'https://www.youtube.com/embed/oG-dD-EJ4es?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            },
          },
          {
            title: 'Розтяжка спини',
            context: '',
            video: 'https://www.youtube.com/embed/KCsQk2ivXIM?modestbranding=1',
            buttons: false,
            modal: {
              context: [],
              body: []
            }
          }
        ],
        prev: 'tonusm1l',
        next: 'tonusm3l'
      }, //done VIDEO
      tonusm3l: {
        title: 'Груди-тріцепс-біцепс',
        mass: [
          {
            title: 'Розминка',
            context: '5-10 хв',
            video: 'https://www.youtube.com/embed/vk30szk1CDs?modestbranding=1',
            buttons: false,
          },
          {
            title: 'Скручування на підлозі лежачи з піднятими ногами',
            context: '',
            video: 'https://www.youtube.com/embed/r-BozOo-6L0?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Планка',
            context: '',
            video: 'https://www.youtube.com/embed/lRdcjyOGXmQ?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '40-90с',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Віджимання від підлоги на колінах',
            context: '',
            video: 'https://www.youtube.com/embed/4XLWULnFyBY?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Жим гантелей під нахилом 45 градусів на лавці',
            context: '',
            video: 'https://www.youtube.com/embed/G-jXkqoF09g?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг на руку'
                },
                {
                  title: '2,3,4 підходи',
                  text: '10 кг на руку'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Підйом гантелей на біцепс',
            context: '',
            video: 'https://www.youtube.com/embed/M-Opf3rAyU8?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '2 кг на руку'
                },
                {
                  title: '2,3,4 підходи',
                  text: '5 кг на руку'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Молотки з гантелями',
            context: '',
            video: 'https://www.youtube.com/embed/OGV4jnSjm9s?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг на руку'
                },
                {
                  title: '2,3,4 підходи',
                  text: '10 кг на руку'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Віджимання від лавки на тріцепс',
            context: '',
            video: 'https://www.youtube.com/embed/A4WQgwVge8Q?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: 'Виконуйте вправу із власною вагою',
                  text: '10-15 разів'
                },
              ],
              body: []
            }
          },
          {
            title: 'Розгинання рук з рівною ручкою',
            context: '',
            video: 'https://www.youtube.com/embed/qbZNqRmLofE?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розтяжка грудей та біцепса',
            context: '',
            video: 'https://www.youtube.com/embed/MgjTmjgWZdU?modestbranding=1',
            buttons: false,
            modal: {
              context: [],
              body: []
            }
          },
        ],
        prev: 'tonusm2l',
        next: 'tonusm1l'
      }, //done VIDEO

      slimm1: {
        title: 'Спина-тріцепс',
        mass: [
          {
            title: 'Розминка',
            context: '5-10 хв',
            video: 'https://www.youtube.com/embed/vk30szk1CDs?modestbranding=1',
            buttons: false,
          },
          {
            title: 'Скручування на підлозі лежачи з піднятими ногами',
            context: '',
            video: 'https://www.youtube.com/embed/r-BozOo-6L0?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Планка',
            context: '',
            video: 'https://www.youtube.com/embed/lRdcjyOGXmQ?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '40-90с',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Гіперекстензія',
            context: '',
            video: 'https://www.youtube.com/embed/0koLScSf5x8?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: 'Виконуйте вправу із власною вагою',
                  text: ''
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Віджимання вузьким хватом від підлоги',
            context: '',
            video: 'https://www.youtube.com/embed/Ku3ALGdMOwA?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: 'Виконуйте вправу із власною вагою',
                  text: ''
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Вертикальна тяга широким хватом',
            context: '',
            video: 'https://www.youtube.com/embed/p4nwFyNYeLc?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '30 кг'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розгибання з прямою ручкою в кроссовері',
            context: '',
            video: 'https://www.youtube.com/embed/qbZNqRmLofE?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '25 кг'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Тяга блоку до поясу сидячи',
            context: '',
            video: 'https://www.youtube.com/embed/hRmlG-jWlEs?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '30 кг'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розгибання з канатом в кроссовері',
            context: '',
            video: 'https://www.youtube.com/embed/oZpGj7k7uWY?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '25 кг'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розтяжка спини та тріцепса',
            context: '',
            video: 'https://www.youtube.com/embed/72UjsoC-v2o?modestbranding=1',
            buttons: false,
            modal: {
              context: [],
              body: []
            }
          }
        ],
        prev: 'slimm3',
        next: 'slimm2'
      }, // good VIDEO
      slimm2: {
        title: 'Груди-біцепс',
        mass: [
          {
            title: 'Розминка',
            context: '5-10 хв',
            video: 'https://www.youtube.com/embed/vk30szk1CDs?modestbranding=1',
            buttons: false,
          },
          {
            title: 'Скручування на підлозі лежачи з піднятими ногами',
            context: '',
            video: 'https://www.youtube.com/embed/r-BozOo-6L0?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Планка',
            context: '',
            video: 'https://www.youtube.com/embed/lRdcjyOGXmQ?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '40-90с',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Жим в хаммері',
            context: '',
            video: 'https://www.youtube.com/embed/sfta1I5jc1I?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '30 кг'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Підйом штанги на біцепс',
            context: '',
            video: 'https://www.youtube.com/embed/49YpWQdsoEU?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: 'Пустий гриф'
                },
                {
                  title: '2,3,4 підходи',
                  text: '10 кг'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Грудь машина',
            context: '',
            video: 'https://www.youtube.com/embed/UNNssl1LwXA?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '30 кг'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Біцепс машина',
            context: '',
            video: 'https://www.youtube.com/embed/DSyVZL3vb2Q?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '10 кг'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Жим штанги лежачі на горизонтальній лавці',
            context: '',
            video: 'https://www.youtube.com/embed/Mjhpz1m2xEo?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: 'Пустий гриф'
                },
                {
                  title: '2,3,4 підходи',
                  text: '10 кг'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Молоти з гантелями',
            context: '',
            video: 'https://www.youtube.com/embed/OGV4jnSjm9s?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '2 кг на руку'
                },
                {
                  title: '2,3,4 підходи',
                  text: '5 кг на руку'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розтяжка грудей та біцепса',
            context: '',
            video: 'https://www.youtube.com/embed/MgjTmjgWZdU?modestbranding=1',
            buttons: false,
            modal: {
              context: [],
              body: []
            }
          },
        ],
        prev: 'slimm1',
        next: 'slimm3'
      }, // good VIDEO
      slimm3: {
        title: 'Ноги-плечі',
        mass: [
          {
            title: 'Розминка',
            context: '5-10 хв',
            video: 'https://www.youtube.com/embed/vk30szk1CDs?modestbranding=1',
            buttons: false,
          },
          {
            title: 'Ікри',
            context: '',
            video: 'https://www.youtube.com/embed/J4DFwTK0ab4?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Жим в хаммері на плечі',
            context: '',
            video: 'https://www.youtube.com/embed/H1SuI-6er_w?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: 'Без ваги'
                },
                {
                  title: '2,3,4 підходи',
                  text: '10 кг'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Розгинання ніг в тренажері',
            context: '',
            video: 'https://www.youtube.com/embed/V9tDbk99-N8?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '30 кг'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Тяга канату до підборіддя в кроссовері',
            context: '',
            video: 'https://www.youtube.com/embed/8ij1yGnm8Ds?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Згибання ніг в тренажері',
            context: '',
            video: 'https://www.youtube.com/embed/ekVIK3_hjCk?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '30 кг'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Підйом ваги перед собою',
            context: '',
            video: 'https://www.youtube.com/embed/DthivbwN5TI?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '2.5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '10 кг'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Жим платформи широкою постановкою ніг',
            context: '',
            video: 'https://www.youtube.com/embed/-CcH6Dd6iOE?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '30 кг'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Розтяжка ніг та плечей',
            context: '',
            video: 'https://www.youtube.com/embed/2T6nYvIXlT8?modestbranding=1',
            buttons: false,
            modal: {
              context: [],
              body: []
            }
          }
        ],
        prev: 'slimm2',
        next: 'slimm1'
      }, // good VIDEO

      tonusg1s: {
        title: 'Ноги-плечі',
        mass: [
          {
            title: 'Розминка',
            context: '5-10 хв',
            video: 'https://www.youtube.com/embed/vk30szk1CDs?modestbranding=1',
            buttons: false,
          },
          {
            title: 'Ікри',
            context: '',
            video: 'https://www.youtube.com/embed/J4DFwTK0ab4?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '10 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Згинання ніг в тренажері',
            context: '',
            video: 'https://www.youtube.com/embed/ekVIK3_hjCk?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '10 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розведення ніг в тренажері',
            context: '',
            video: 'https://www.youtube.com/embed/JZ5u5MRw8-M?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Сідничний міст на підлозі',
            context: '',
            video: 'https://www.youtube.com/embed/qtnr_Bqq2iE?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '10 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Жим платформи з вузькою постановкою ніг',
            context: '',
            video: 'https://www.youtube.com/embed/tKD0QG6yj88?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '10 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Жим гантелей над головою сидячи',
            context: '',
            video: 'https://www.youtube.com/embed/tvAF8xjnEK0?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '2 кг на руку'
                },
                {
                  title: '2,3,4 підходи',
                  text: '5 кг на руку'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розтяжка ніг та плечей',
            context: '',
            video: 'https://www.youtube.com/embed/2T6nYvIXlT8?modestbranding=1',
            buttons: false,
            modal: {
              context: [],
              body: []
            }
          },
        ],
        prev: 'tonusg3s',
        next: 'tonusg2s'
      }, // done VIDEO
      tonusg2s: {
        title: 'Спина',
        mass: [
          {
            title: 'Розминка',
            context: '5-10 хв',
            video: 'https://www.youtube.com/embed/vk30szk1CDs?modestbranding=1',
            buttons: false,
          },
          {
            title: 'Скручування на підлозі лежачи з піднятими ногами',
            context: '',
            video: 'https://www.youtube.com/embed/r-BozOo-6L0?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Планка',
            context: '',
            video: 'https://www.youtube.com/embed/lRdcjyOGXmQ?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '40-90с',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Гіперекстензія',
            context: '',
            video: 'https://www.youtube.com/embed/0koLScSf5x8?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: 'Виконуйте вправу із власною вагою',
                  text: ''
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Тяга верхнього блоку з вузькою ручкою',
            context: '',
            video: 'https://www.youtube.com/embed/N9gXdYi3ec8?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '15 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Тяга нижнього блоку з вузькою ручкою',
            context: '',
            video: 'https://www.youtube.com/embed/JCf3CREeLTE?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '15 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Важільна тяга',
            context: '',
            video: 'https://www.youtube.com/embed/oG-dD-EJ4es?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '15 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розтяжка спини',
            context: '',
            video: 'https://www.youtube.com/embed/KCsQk2ivXIM?modestbranding=1',
            buttons: false,
            modal: {
              context: [],
              body: []
            }
          }
        ],
        prev: 'tonusg1s',
        next: 'tonusg3s'
      }, //done VIDEO
      tonusg3s: {
        title: 'Груди-тріцепс-біцепс',
        mass: [
          {
            title: 'Розминка',
            context: '5-10 хв',
            video: 'https://www.youtube.com/embed/vk30szk1CDs?modestbranding=1',
            buttons: false,
          },
          {
            title: 'Скручування на підлозі лежачи з піднятими ногами',
            context: '',
            video: 'https://www.youtube.com/embed/r-BozOo-6L0?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Планка',
            context: '',
            video: 'https://www.youtube.com/embed/lRdcjyOGXmQ?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '40-90с',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Віджимання від підлоги на колінах',
            context: '',
            video: 'https://www.youtube.com/embed/4XLWULnFyBY?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '15 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Жим гантелей під нахилом 45 градусів на лавці',
            context: '',
            video: 'https://www.youtube.com/embed/G-jXkqoF09g?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '2 кг на руку'
                },
                {
                  title: '2,3,4 підходи',
                  text: '5 кг на руку'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Підйом гантелей на біцепс',
            context: '',
            video: 'https://www.youtube.com/embed/M-Opf3rAyU8?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '2 кг на руку'
                },
                {
                  title: '2,3,4 підходи',
                  text: '5 кг на руку'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Молотки з гантелями',
            context: '',
            video: 'https://www.youtube.com/embed/OGV4jnSjm9s?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '2 кг на руку'
                },
                {
                  title: '2,3,4 підходи',
                  text: '5 кг на руку'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Віджимання від лавки на тріцепс',
            context: '',
            video: 'https://www.youtube.com/embed/A4WQgwVge8Q?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: 'Виконуйте вправу із власною вагою',
                  text: '10-15 разів'
                },
              ],
              body: []
            }
          },
          {
            title: 'Розгинання рук з рівною ручкою',
            context: '',
            video: 'https://www.youtube.com/embed/qbZNqRmLofE?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '10 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розтяжка грудей та біцепса',
            context: '',
            video: 'https://www.youtube.com/embed/MgjTmjgWZdU?modestbranding=1',
            buttons: false,
            modal: {
              context: [],
              body: []
            }
          },
        ],
        prev: 'tonusg2s',
        next: 'tonusg1s'
      }, //done VIDEO

      tonusg1m: {
        title: 'Ноги-плечі',
        mass: [
          {
            title: 'Розминка',
            context: '5-10 хв',
            video: 'https://www.youtube.com/embed/vk30szk1CDs?modestbranding=1',
            buttons: false,
          },
          {
            title: 'Ікри',
            context: '',
            video: 'https://www.youtube.com/embed/J4DFwTK0ab4?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Згинання ніг в тренажері',
            context: '',
            video: 'https://www.youtube.com/embed/ekVIK3_hjCk?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розведення ніг в тренажері',
            context: '',
            video: 'https://www.youtube.com/embed/JZ5u5MRw8-M?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '15 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '40 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Сідничний міст на підлозі',
            context: '',
            video: 'https://www.youtube.com/embed/qtnr_Bqq2iE?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '10 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Жим платформи з вузькою постановкою ніг',
            context: '',
            video: 'https://www.youtube.com/embed/tKD0QG6yj88?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Жим гантелей над головою сидячи',
            context: '',
            video: 'https://www.youtube.com/embed/tvAF8xjnEK0?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '2 кг на руку'
                },
                {
                  title: '2,3,4 підходи',
                  text: '5 кг на руку'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розтяжка ніг та плечей',
            context: '',
            video: 'https://www.youtube.com/embed/2T6nYvIXlT8?modestbranding=1',
            buttons: false,
            modal: {
              context: [],
              body: []
            }
          },
        ],
        prev: 'tonusg3m',
        next: 'tonusg2m'
      }, //done VIDEO
      tonusg2m: {
        title: 'Спина',
        mass: [
          {
            title: 'Розминка',
            context: '5-10 хв',
            video: 'https://www.youtube.com/embed/vk30szk1CDs?modestbranding=1',
            buttons: false,
          },
          {
            title: 'Скручування на підлозі лежачи з піднятими ногами',
            context: '',
            video: 'https://www.youtube.com/embed/r-BozOo-6L0?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Планка',
            context: '',
            video: 'https://www.youtube.com/embed/lRdcjyOGXmQ?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '40-90с',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Гіперекстензія',
            context: '',
            video: 'https://www.youtube.com/embed/0koLScSf5x8?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: 'Виконуйте вправу із власною вагою',
                  text: ''
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Тяга верхнього блоку з вузькою ручкою',
            context: '',
            video: 'https://www.youtube.com/embed/N9gXdYi3ec8?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '30 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Тяга нижнього блоку з вузькою ручкою',
            context: '',
            video: 'https://www.youtube.com/embed/JCf3CREeLTE?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '30 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Важільна тяга',
            context: '',
            video: 'https://www.youtube.com/embed/oG-dD-EJ4es?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розтяжка спини',
            context: '',
            video: 'https://www.youtube.com/embed/KCsQk2ivXIM?modestbranding=1',
            buttons: false,
            modal: {
              context: [],
              body: []
            }
          },
        ],
        prev: 'tonusg1m',
        next: 'tonusg3m'
      }, // done VIDEO
      tonusg3m: {
        title: 'Груди-тріцепс-біцепс',
        mass: [
          {
            title: 'Розминка',
            context: '5-10 хв',
            video: 'https://www.youtube.com/embed/vk30szk1CDs?modestbranding=1',
            buttons: false,
          },
          {
            title: 'Скручування на підлозі лежачи з піднятими ногами',
            context: '',
            video: 'https://www.youtube.com/embed/r-BozOo-6L0?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Планка',
            context: '',
            video: 'https://www.youtube.com/embed/lRdcjyOGXmQ?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '40-90с',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Віджимання від підлоги на колінах',
            context: '',
            video: 'https://www.youtube.com/embed/4XLWULnFyBY?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Жим гантелей під нахилом 45 градусів на лавці',
            context: '',
            video: 'https://www.youtube.com/embed/G-jXkqoF09g?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг на руку'
                },
                {
                  title: '2,3,4 підходи',
                  text: '10 кг на руку'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Підйом гантелей на біцепс',
            context: '',
            video: 'https://www.youtube.com/embed/M-Opf3rAyU8?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '2 кг на руку'
                },
                {
                  title: '2,3,4 підходи',
                  text: '5 кг на руку'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Молотки з гантелями',
            context: '',
            video: 'https://www.youtube.com/embed/OGV4jnSjm9s?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг на руку'
                },
                {
                  title: '2,3,4 підходи',
                  text: '10 кг на руку'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Віджимання від лавки на тріцепс',
            context: '',
            video: 'https://www.youtube.com/embed/A4WQgwVge8Q?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: 'Виконуйте вправу із власною вагою',
                  text: '10-15 разів'
                },
              ],
              body: []
            }
          },
          {
            title: 'Розгинання рук з рівною ручкою',
            context: '',
            video: 'https://www.youtube.com/embed/qbZNqRmLofE?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '15 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розтяжка грудей та біцепса',
            context: '',
            video: 'https://www.youtube.com/embed/MgjTmjgWZdU?modestbranding=1',
            buttons: false,
            modal: {
              context: [],
              body: []
            }
          },
        ],
        prev: 'tonusg2m',
        next: 'tonusg1m'
      }, // done VIDEO

      tonusg1l: {
        title: 'Ноги-плечі',
        mass: [
          {
            title: 'Розминка',
            context: '5-10 хв',
            video: 'https://www.youtube.com/embed/vk30szk1CDs?modestbranding=1',
            buttons: false,
          },
          {
            title: 'Ікри',
            context: '',
            video: 'https://www.youtube.com/embed/J4DFwTK0ab4?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Згинання ніг в тренажері',
            context: '',
            video: 'https://www.youtube.com/embed/ekVIK3_hjCk?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '30 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розведення ніг в тренажері',
            context: '',
            video: 'https://www.youtube.com/embed/JZ5u5MRw8-M?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '15 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '40 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Сідничний міст на підлозі',
            context: '',
            video: 'https://www.youtube.com/embed/qtnr_Bqq2iE?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '10 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Жим платформи з вузькою постановкою ніг',
            context: '',
            video: 'https://www.youtube.com/embed/tKD0QG6yj88?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '30 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Жим гантелей над головою сидячи',
            context: '',
            video: 'https://www.youtube.com/embed/tvAF8xjnEK0?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '2 кг на руку'
                },
                {
                  title: '2,3,4 підходи',
                  text: '5 кг на руку'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розтяжка ніг та плечей',
            context: '',
            video: 'https://www.youtube.com/embed/2T6nYvIXlT8?modestbranding=1',
            buttons: false,
            modal: {
              context: [],
              body: []
            }
          },
        ],
        prev: 'tonusg3l',
        next: 'tonusg2l'
      }, // done VIDEO
      tonusg2l: {
        title: 'Спина',
        mass: [
          {
            title: 'Розминка',
            context: '5-10 хв',
            video: 'https://www.youtube.com/embed/vk30szk1CDs?modestbranding=1',
            buttons: false,
          },
          {
            title: 'Скручування на підлозі лежачи з піднятими ногами',
            context: '',
            video: 'https://www.youtube.com/embed/r-BozOo-6L0?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Планка',
            context: '',
            video: 'https://www.youtube.com/embed/lRdcjyOGXmQ?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '40-90с',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Гіперекстензія',
            context: '',
            video: 'https://www.youtube.com/embed/0koLScSf5x8?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: 'Виконуйте вправу із власною вагою',
                  text: ''
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Тяга верхнього блоку з вузькою ручкою',
            context: '',
            video: 'https://www.youtube.com/embed/N9gXdYi3ec8?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '30 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Тяга нижнього блоку з вузькою ручкою',
            context: '',
            video: 'https://www.youtube.com/embed/JCf3CREeLTE?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '30 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Важільна тяга',
            context: '',
            video: 'https://www.youtube.com/embed/KCsQk2ivXIM?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розтяжка спини',
            context: '',
            video: 'https://www.youtube.com/embed/KCsQk2ivXIM?modestbranding=1',
            buttons: false,
            modal: {
              context: [],
              body: []
            }
          },
        ],
        prev: 'tonusg1l',
        next: 'tonusg3l'
      }, /// done VIDEO
      tonusg3l: {
        title: 'Груди-тріцепс-біцепс',
        mass: [
          {
            title: 'Розминка',
            context: '5-10 хв',
            video: 'https://www.youtube.com/embed/vk30szk1CDs?modestbranding=1',
            buttons: false,
          },
          {
            title: 'Скручування на підлозі лежачи з піднятими ногами',
            context: '',
            video: 'https://www.youtube.com/embed/r-BozOo-6L0?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Планка',
            context: '',
            video: 'https://www.youtube.com/embed/lRdcjyOGXmQ?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '40-90с',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Віджимання від підлоги на колінах',
            context: '',
            video: 'https://www.youtube.com/embed/4XLWULnFyBY?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Жим гантелей під нахилом 45 градусів на лавці',
            context: '',
            video: 'https://www.youtube.com/embed/G-jXkqoF09g?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг на руку'
                },
                {
                  title: '2,3,4 підходи',
                  text: '10 кг на руку'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Підйом гантелей на біцепс',
            context: '',
            video: 'https://www.youtube.com/embed/M-Opf3rAyU8?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '2 кг на руку'
                },
                {
                  title: '2,3,4 підходи',
                  text: '5 кг на руку'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Молотки з гантелями',
            context: '',
            video: 'https://www.youtube.com/embed/OGV4jnSjm9s?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг на руку'
                },
                {
                  title: '2,3,4 підходи',
                  text: '10 кг на руку'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Віджимання від лавки на тріцепс',
            context: '',
            video: 'https://www.youtube.com/embed/A4WQgwVge8Q?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: 'Виконуйте вправу із власною вагою',
                  text: '10-15 разів'
                },
              ],
              body: []
            }
          },
          {
            title: 'Розгинання рук з рівною ручкою',
            context: '',
            video: 'https://www.youtube.com/embed/qbZNqRmLofE?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '10-15 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розтяжка грудей та біцепса',
            context: '',
            video: 'https://www.youtube.com/embed/MgjTmjgWZdU?modestbranding=1',
            buttons: false,
            modal: {
              context: [],
              body: []
            }
          },
        ],
        prev: 'tonusg2l',
        next: 'tonusg1l'
      }, // done VIDEO

      slimg1: {
        title: 'Ноги',
        mass: [
          {
            title: 'Кардіо',
            context: '10-15 хв',
            video: 'https://www.youtube.com/embed/M5jAWX4W88U?modestbranding=1',
            buttons: false,
          },
          {
            title: 'Розминка',
            context: '5-10 хв',
            video: 'https://www.youtube.com/embed/vk30szk1CDs?modestbranding=1',
            buttons: false,
          },
          {
            title: 'Скручування на підлозі лежачи з піднятими ногами',
            context: '',
            video: 'https://www.youtube.com/embed/r-BozOo-6L0?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Планка',
            context: '',
            video: 'https://www.youtube.com/embed/lRdcjyOGXmQ?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '40-90с',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розгинач стегна',
            context: '',
            video: 'https://www.youtube.com/embed/V9tDbk99-N8?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Згинач стегна',
            context: '',
            video: 'https://www.youtube.com/embed/ekVIK3_hjCk?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Зведення  ніг',
            context: '',
            video: 'https://www.youtube.com/embed/DH3cpNkxay8?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розведення ніг',
            context: '',
            video: 'https://www.youtube.com/embed/JZ5u5MRw8-M?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '35 кг'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Відведення ноги в кроссовері',
            context: '',
            video: 'https://www.youtube.com/embed/LkA3DnARkAI?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розтяжка ніг',
            context: '',
            video: 'https://www.youtube.com/embed/2T6nYvIXlT8?modestbranding=1',
            buttons: false,
            modal: {
              context: [],
              body: []
            }
          },
        ],
        prev: 'slimg3',
        next: 'slimg2'
      }, // done VIDEO
      slimg2: {
        title: 'Спина-біцепс',
        mass: [
          {
            title: 'Кардіо',
            context: '10-15 хв',
            video: 'https://www.youtube.com/embed/M5jAWX4W88U?modestbranding=1',
            buttons: false,
            modal: {
              context: [],
              body: []
            }
          },
          {
            title: 'Розминка',
            context: '5-10 хв',
            video: 'https://www.youtube.com/embed/vk30szk1CDs?modestbranding=1',
            buttons: false,
          },
          {
            title: 'Скручування на підлозі лежачи з піднятими ногами',
            context: '',
            video: 'https://www.youtube.com/embed/r-BozOo-6L0?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Планка',
            context: '',
            video: 'https://www.youtube.com/embed/lRdcjyOGXmQ?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '40-90с',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Гіперекстензія',
            context: '',
            video: 'https://www.youtube.com/embed/0koLScSf5x8?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: 'Виконуйте вправу із власною вагою',
                  text: ''
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв. між',
                  text: 'підходами'
                },
              ]
            }
          },
          {
            title: 'Вертикальна тяга',
            context: '',
            video: 'https://www.youtube.com/embed/p4nwFyNYeLc?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '25 кг'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Горизонтальна тяга',
            context: '',
            video: 'https://www.youtube.com/embed/hRmlG-jWlEs?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '25 кг'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Човник',
            context: '',
            video: 'https://www.youtube.com/embed/EpnB_YaXZDg?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: 'Виконуйте вправу із власною вагою',
                  text: '15-20 разів'
                },
              ],
              body: []
            }
          },
          {
            title: 'Біцепс гантелі',
            context: '',
            video: 'https://www.youtube.com/embed/M-Opf3rAyU8?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '2 кг на руку'
                },
                {
                  title: '2,3,4 підходи',
                  text: '5 кг на руку'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Молотки',
            context: '',
            video: 'https://www.youtube.com/embed/OGV4jnSjm9s?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '2 кг на руку'
                },
                {
                  title: '2,3,4 підходи',
                  text: '5 кг на руку'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розтяжка спини та біцепса',
            context: '',
            video: 'https://www.youtube.com/embed/TRYuLOqx1uA?modestbranding=1',
            buttons: false,
            modal: {
              context: [],
              body: []
            }
          }
        ],
        prev: 'slimg1',
        next: 'slimg3'
      }, // done VIDEO
      slimg3: {
        title: 'Груди-тріцепс-плечі',
        mass: [
          {
            title: 'Кардіо',
            context: '10-15 хв',
            video: 'https://www.youtube.com/embed/M5jAWX4W88U?modestbranding=1',
            buttons: false,
            modal: {
              context: [
              ],
              body: []
            }
          },
          {
            title: 'Розминка',
            context: '5-10 хв',
            video: 'https://www.youtube.com/embed/vk30szk1CDs?modestbranding=1',
            buttons: false,
          },
          {
            title: 'Скручування на підлозі лежачи з піднятими ногами',
            context: '',
            video: 'https://www.youtube.com/embed/r-BozOo-6L0?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Планка',
            context: '',
            video: 'https://www.youtube.com/embed/lRdcjyOGXmQ?modestbranding=1',
            buttons: true,
            modal: {
              context: [],
              body: [
                {
                  title: '40-90с',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Грудь машина з акцентом на верх грудей',
            context: '',
            video: 'https://www.youtube.com/embed/UNNssl1LwXA?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '10 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '30 кг'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Махи гантелей лежачи на ногах',
            context: '',
            video: 'https://www.youtube.com/embed/log1WNqJssA?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '2 кг на руку'
                },
                {
                  title: '2,3,4 підходи',
                  text: '5 кг на руку'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Жим гантелей на лавці під кутом 45 градусів',
            context: '',
            video: 'https://www.youtube.com/embed/G-jXkqoF09g?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг на руку'
                },
                {
                  title: '2,3,4 підходи',
                  text: '10 кг на руку'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Тріцепс у кроссовері пряма ручка',
            context: '',
            video: 'https://www.youtube.com/embed/qbZNqRmLofE?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Підйом гантелей перед собою',
            context: '',
            video: 'https://www.youtube.com/embed/-xX7qElF0cM?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '2 кг на руку'
                },
                {
                  title: '2,3,4 підходи',
                  text: '5 кг на руку'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Тріцепс у кроссовері ручка канат',
            context: '',
            video: 'https://www.youtube.com/embed/oZpGj7k7uWY?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '5 кг'
                },
                {
                  title: '2,3,4 підходи',
                  text: '20 кг'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Підйом гантелей на середні дельти',
            context: '',
            video: 'https://www.youtube.com/embed/tvAF8xjnEK0?modestbranding=1',
            buttons: true,
            modal: {
              context: [
                {
                  title: '1 підход - розминочний',
                  text: '2 кг на руку'
                },
                {
                  title: '2,3,4 підходи',
                  text: '5 кг на руку'
                },
              ],
              body: [
                {
                  title: '15-20 разів',
                  text: 'за підход'
                },
                {
                  title: '4',
                  text: 'підходи'
                },
                {
                  title: '1-2хв.',
                  text: 'між підходами'
                },
              ]
            }
          },
          {
            title: 'Розтяжка грудей та тріцепс',
            context: '',
            video: 'https://www.youtube.com/embed/ic3rYCa5Rus?modestbranding=1',
            buttons: false,
            modal: {
              context: [],
              body: []
            }
          },
        ],
        prev: 'slimg2',
        next: 'slimg1'
      }, // done VIDEO
    }
  }),
  methods: {
    toggleModal(data) {
      this.isShowModal = !this.isShowModal;
      if(this.isShowModal){
        this.dataModal = data
      }
      else {
        this.dataModal = {
          title: '',
          context: '',
          body: ''
        }
      }
    },
  },
  computed: {
    ...mapGetters(['getUsers'])
  },
  async created() {
    this.vds = this.$route.query.type
  }
}
</script>

<style lang="scss" scoped>
.prevNext{
  margin:7px;
  cursor:pointer
}
.workoutProgram{
  margin-top:30px;
}
.blockProgram{
  display:flex;
  flex-wrap:wrap;
}
.blockProgramTitle{
  display:flex;
  flex-direction:column;
  align-self:center;
  align-items:center;

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;


  text-align:center;
  /* light blue */

  color: #49A7C9;

  width:300px;

}
.blockProgramImg{
  margin:20px;
  iframe{
    width: 450px;
    height: 250px;
    border: 0;
  }
}
.blockProgramButton{
  padding:10px 40px;

  background: rgba(73, 167, 201, 0.1);
  /* light blue */

  border: 3px solid #49A7C9;
  box-sizing: border-box;
  backdrop-filter: blur(15px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 40px;

  margin-top:20px;

  cursor: pointer;
}
.programBlockTitle{
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 35px;
  text-align: center;

  color: #F8F8F8;

}

@media screen and (max-width:1169px){
  .blockProgram{
    display:flex;
    flex-direction:column;

    justify-self:center;
    justify-items:center;
    justify-content:center;
  }

  iframe{
    width: 250px !important;
    height: 190px !important;
  }
  .blockProgramTitle{
    width:250px;
    font-size:23px;
  }
  .simple-modal-content{
    width:85%;
  }
  .simple-modal-header{
    font-size:18px;
  }
  .simple-modal-body div{
    font-size:15px;
  }
}


</style>
