<template>
  <div>
    <div style="display:flex; align-items:center; align-self:center; align-content:center; height:90vh">
      <div class="blurBlock">
        <div class="blurBlockPadding">
          <div class="blurBlockPaddingText">
            <div class="loginForm">
              <div class="login">
                <label>
                  <input v-model="firstName" placeholder="Ваше ім'я" type="text" required>
                </label>
              </div>
              <div class="login">
                <label>
                  <input v-model="lastName" placeholder="Ваше прізвище" type="text" required>
                </label>
              </div>
              <div class="login">
                <label>
                  <input v-model="phoneNumber" placeholder="Номер телефону" type="tel">
                </label>
              </div>
              <div class="login">
                <label>
                  <input v-model="email" type="Email" placeholder="Ваш емейл" required>
                </label>
              </div>
              <div class="loginSelect">
                <label>
                  <select v-model="city">
                    <option>КРОПИВНИЦКИЙ</option>
                    <option>СМЕЛА</option>
                    <option>ЧЕРКАССЫ</option>
                    <option>ХМЕЛЬНИЦКИЙ</option>
                  </select>
                </label>
              </div>
              <button @click="submit" class="loginButton oneBlockButton">ПРИДБАТИ</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
  name: "registation",
  data() {
    return{
      firstName: '',
      lastName: '',
      phoneNumber: '+380',
      email: '',
      city: '',
    }
  },
  methods: {
    ...mapActions(['getNewPassword', 'getRegister']),
    async submit() {
      await this.getRegister({
        firstName: this.firstName,
        lastName: this.lastName,
        phoneNumber: this.phoneNumber,
        email: this.email,
        password: this.getPass,
        gender: this.regGetters.gender,
        bodyType: this.regGetters.bodyType,
        packId: this.regGetters.packId,
        weight: this.regGetters.weight,
        city: this.city
      })
      await this.$router.push('/oplata?packId=' + this.regGetters.packId + '&bodyType=' + this.regGetters.bodyType)
    }
  },
  computed: {
    ...mapGetters(['getPass', 'regGetters'])
  },
  created() {
    this.getNewPassword()
  }
}
</script>

<style lang="scss" scoped>
.loginForm{
  display:flex;
  flex-direction:column;
  align-content:center;
  justify-content:center;
  width:280px;
  margin: 0 auto;

  .login{
    line-height: 10px;
    display:flex;

    label{
      input{
        background: #888888;

        border-radius: 40px;
        border:none;

        width:245px;

        margin: 5px;

        padding:10px;

        color:#DBDADA;

        &::placeholder{
          color:#DBDADA;
        }
      }
    }
  }
  .loginSelect{
    line-height: 10px;
    display:flex;

    label{
      select{
        background: #888888;

        border-radius: 40px;
        border:none;

        width:245px;

        margin:5px;
        padding:10px;

        color:#DBDADA;

      }
    }
  }
  .loginButton{
    width:70%;
    margin: 0 auto;
    height:50px;
    margin-top:30px;
    margin-bottom:50px;
    margin-left: 30px;
  }
}
</style>
