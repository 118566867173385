<template>
  <div>
    <div v-if="oplataGetters" style="margin: 0 auto; display: flex; justify-content: space-evenly;">
      <div v-html="oplataGetters"></div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "linkpay",
  computed: {
    ...mapGetters(['oplataGetters'])
  }
}
</script>

<style scoped>
.wayforpay-form form .btn{
  background: linear-gradient(180deg, #E37E77 0%, #C6291E 100%);
  box-shadow: inset 0 0 1px #C6291E;

  border-radius: 40px;

  padding:10px 20px;

  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  text-align: center;

  cursor:pointer;
  color: #FFDADA;

  border: none;

  text-decoration:none;
}
</style>
