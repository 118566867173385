<template>
  <div id="app">
    <nav-menu/>
    <router-view/>
    <button-block/>
  </div>
</template>

<script>

import NavMenu from "./components/comp/navMenu";
import buttonBlock from "./components/comp/buttonBlock";
import {mapActions} from "vuex";

export default {
  name: 'App',
  components: {NavMenu, buttonBlock},
  methods: {
    ...mapActions(['getSessionInfo']),
  },
  async created() {
    await this.getSessionInfo()
  }
}
</script>

<style lang="scss">

</style>
