<template>
  <div>
    <div>
      <h1 class="programTitle">
        Програма ваших тренувань
      </h1>
      <div v-for="(index, key) in getUsers.buyedList" :key="key">
        <div v-if="key === '0'">
          <div v-for="(indexs, keys) in index" :key="keys">
            <div v-if="indexs && keys === '0'" class="programCenter">
              <h3 class="programTitle">Набір чоловік мала вага</h3>
              <div class="program">
                <div class="programBorder">
                  <router-link class="programBlock" to="/workouttrener?type=nabirm1s">
                    Груди-тріцепс-плечі
                  </router-link>
                </div>
              </div>
              <div class="program">
                <div class="programBorder">
                  <router-link class="programBlock" to="/workouttrener?type=nabirm2s">
                    Ноги-плечі
                  </router-link>
                </div>
              </div>
              <div class="program">
                <div class="programBorder">
                  <router-link class="programBlock" to="/workouttrener?type=nabirm3s">
                    Спина-біцепс
                  </router-link>
                </div>
              </div>
            </div> <!-- NABIR M  -->
            <div v-if="indexs && keys === '1'" class="programCenter">
              <h3 class="programTitle">Набір чоловік середня вага</h3>
              <div class="program">
                <div class="programBorder">
                  <router-link class="programBlock" to="/workouttrener?type=nabirm1m">
                    Груди-тріцепс-плечі
                  </router-link>
                </div>
              </div>
              <div class="program">
                <div class="programBorder">
                  <router-link class="programBlock" to="/workouttrener?type=nabirm2m">
                    Ноги-плечі
                  </router-link>
                </div>
              </div>
              <div class="program">
                <div class="programBorder">
                  <router-link class="programBlock" to="/workouttrener?type=nabirm3m">
                    Спина-біцепс
                  </router-link>
                </div>
              </div>
            </div> <!-- NABIR M  -->
          </div>
        </div>
        <div v-if="key === '1'">
          <div v-for="(indexs, keys) in index" :key="keys">
            <div v-if="indexs && keys === '1'" class="programCenter">
              <h3 class="programTitle">Тонус чоловік середня вага</h3>
              <div class="program">
                <div class="programBorder">
                  <router-link class="programBlock" to="/workouttrener?type=tonusm1m">
                    Ноги-плечі
                  </router-link>
                </div>
              </div>
              <div class="program">
                <div class="programBorder">
                  <router-link class="programBlock" to="/workouttrener?type=tonusm2m">
                    Спина
                  </router-link>
                </div>
              </div>
              <div class="program">
                <div class="programBorder">
                  <router-link class="programBlock" to="/workouttrener?type=tonusm3m">
                    Груди-тріцепс-біцепс
                  </router-link>
                </div>
              </div>
            </div> <!-- TONUS M  -->
            <div v-if="indexs && keys === '2'" class="programCenter">
              <h3 class="programTitle">Набір чоловік велика вага</h3>
              <div class="program">
                <div class="programBorder">
                  <router-link class="programBlock" to="/workouttrener?type=tonusm1l">
                    Ноги-плечі
                  </router-link>
                </div>
              </div>
              <div class="program">
                <div class="programBorder">
                  <router-link class="programBlock" to="/workouttrener?type=tonusm2l">
                    Спина
                  </router-link>
                </div>
              </div>
              <div class="program">
                <div class="programBorder">
                  <router-link class="programBlock" to="/workouttrener?type=tonusm3l">
                    Груди-тріцепс-біцепс
                  </router-link>
                </div>
              </div>
            </div> <!-- TONUS M  -->
          </div>
        </div>
        <div v-if="key === '2'">
          <div v-for="(indexs, keys) in index" :key="keys">
            <div v-if="indexs && keys === '2'" class="programCenter">
              <h3 class="programTitle">Сухднення чоловік велика вага</h3>
              <div class="program">
                <div class="programBorder">
                  <router-link class="programBlock" to="/workouttrener?type=slimm1">
                    Спина-тріцепс
                  </router-link>
                </div>
              </div>
              <div class="program">
                <div class="programBorder">
                  <router-link class="programBlock" to="/workouttrener?type=slimm2">
                    Груди-біцепс
                  </router-link>
                </div>
              </div>
              <div class="program">
                <div class="programBorder">
                  <router-link class="programBlock" to="/workouttrener?type=slimm3">
                    Ноги-плечі
                  </router-link>
                </div>
              </div>
            </div> <!-- SLIM M  -->
          </div>
        </div>
        <div v-if="key === '10'">
          <div v-for="(indexs, keys) in index" :key="keys">
            <div v-if="indexs && keys === '0'" class="programCenter">
              <h3 class="programTitle">Тонус дівчина мала вага</h3>
              <div class="program">
                <div class="programBorder">
                  <router-link class="programBlock" to="/workouttrener?type=tonusg1s">
                    Ноги-плечі
                  </router-link>
                </div>
              </div>
              <div class="program">
                <div class="programBorder">
                  <router-link class="programBlock" to="/workouttrener?type=tonusg2s">
                    Спина
                  </router-link>
                </div>
              </div>
              <div class="program">
                <div class="programBorder">
                  <router-link class="programBlock" to="/workouttrener?type=tonusg3s">
                    Груди-тріцепс-біцепс
                  </router-link>
                </div>
              </div>
            </div> <!-- TONUS F  -->
            <div v-if="indexs && keys === '1'" class="programCenter">
              <h3 class="programTitle">Тонус дівчина середня вага</h3>
              <div class="program">
                <div class="programBorder">
                  <router-link class="programBlock" to="/workouttrener?type=tonusg1m">
                    Ноги-плечі
                  </router-link>
                </div>
              </div>
              <div class="program">
                <div class="programBorder">
                  <router-link class="programBlock" to="/workouttrener?type=tonusg2m">
                    Спина
                  </router-link>
                </div>
              </div>
              <div class="program">
                <div class="programBorder">
                  <router-link class="programBlock" to="/workouttrener?type=tonusg3m">
                    Груди-тріцепс-біцепс
                  </router-link>
                </div>
              </div>
            </div> <!-- TONUS F  -->
            <div v-if="indexs && keys === '2'" class="programCenter">
              <h3 class="programTitle">Тонус дівчина велика вага</h3>
              <div class="program">
                <div class="programBorder">
                  <router-link class="programBlock" to="/workouttrener?type=tonusg1l">
                    Ноги-плечі
                  </router-link>
                </div>
              </div>
              <div class="program">
                <div class="programBorder">
                  <router-link class="programBlock" to="/workouttrener?type=tonusg2l">
                    Спина
                  </router-link>
                </div>
              </div>
              <div class="program">
                <div class="programBorder">
                  <router-link class="programBlock" to="/workouttrener?type=tonusg3l">
                    Груди-тріцепс-біцепс
                  </router-link>
                </div>
              </div>
            </div> <!-- TONUS F  -->
          </div>
        </div>
        <div v-if="key === '11'">
          <div v-for="(indexs, keys) in index" :key="keys">
            <div v-if="indexs && keys === '2'" class="programCenter">
              <h3 class="programTitle">Схуднення дівчина велика вага</h3>
              <div class="program">
                <div class="programBorder">
                  <router-link class="programBlock" to="/workouttrener?type=slimg1">
                    Ноги
                  </router-link>
                </div>
              </div>
              <div class="program">
                <div class="programBorder">
                  <router-link class="programBlock" to="/workouttrener?type=slimg2">
                    Спина-біцепс
                  </router-link>
                </div>
              </div>
              <div class="program">
                <div class="programBorder">
                  <router-link class="programBlock" to="/workouttrener?type=slimg3">
                    Груди-тріцепс-плечі
                  </router-link>
                </div>
              </div>
            </div> <!-- SLIM F  -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "workout",
  computed: {
    ...mapGetters(['getUsers'])
  },
  methods: {
    ...mapActions(['getSessionInfo'])
  },
  created() {
    this.getSessionInfo()
  }
}
</script>

<style lang="scss" scoped>
.programTitle{
  margin-top:30px;
}
</style>
