import Vue from 'vue';
import VueRouter from 'vue-router';
import home from './../components/pages/index'
import result from "../components/pages/result";
import login from "../components/pages/login";
import registation from "../components/pages/registation";
import confidentiality from "../components/pages/confidentiality";
import profile from "../components/pages/profile";
import workout from "../components/pages/workout";
import workoutTrener from "../components/pages/workoutTrener";
import oplata from "../components/pages/oplata";
import linkpay from "../components/pages/linkpay";
import trenerovki from "../components/pages/trenerovki";
import resultBuy from "../components/pages/resultBuy";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'pageHome',
        component: home
    },
    {
        path: '/result',
        name: 'result',
        component: result
    },
    {
        path: '/login',
        name: 'login',
        component: login
    },
    {
        path: '/registation',
        name: 'registation',
        component: registation
    },
    {
        path: '/profile',
        name: 'profile',
        component: profile
    },
    {
        path: '/confidentiality',
        name: 'confidentiality',
        component: confidentiality
    },
    {
        path: '/workout',
        name: 'workout',
        component: workout
    },
    {
        path: '/workouttrener',
        name: 'workouttrener',
        component: workoutTrener
    },
    {
        path: '/oplata',
        name: 'oplata',
        component: oplata
    },
    {
        path: '/linkpay',
        name: 'linkpay',
        component: linkpay
    },
    {
        path: '/trenirovki',
        name: 'trenirovki',
        component: trenerovki
    },
    {
        path: '/resultbuy',
        name: 'resultbuy',
        component: resultBuy
    }
];

const router = new VueRouter({
    mode: "history",
    routes,
});

export default router;
