<template>
  <div style="display:flex; flex-direction:column; justify-items:center; justify-self:center; justify-content:center; width:100vw">
    <div class="oneBlock">
      <div class="oneBlockText">
        <div class="oneBlockTextHead">
          <h6>
            Місце твого перетворення
          </h6>
          <h1>
            ФІТНЕС-ТРЕНЕР
          </h1>
          <h2>
            У ТВОЄМУ ТЕЛЕФОНІ
          </h2>
        </div>
        <div class="oneBlockTextContext">
          <p>
            Пройди тест та дізнайся який курс підійде саме тобі
          </p>
        </div>
        <a href="#quiz" style="text-decoration:none">
          <button class="oneBlockButton mobileDisNone">ПРОЙТИ ТЕСТ</button>
        </a>
      </div>
      <div class="oneBlockImg">
        <img src="./../../assets/img/oneBlockImg.png">
        <a href="#quiz" style="text-decoration:none">
          <button class="oneBlockButton mobileBlock">ПРОЙТИ ТЕСТ</button>
        </a>
      </div>
    </div>
    <quiz id="quiz"/>
  </div>
</template>

<script>
import quiz from "../comp/quiz/index";

export default {
  components: {quiz}
}
</script>

<style lang="scss">

</style>
