<template>
  <div>
    <h2 style="color: #fff; text-align: center; font-family: Roboto,sans-serif; margin-bottom: 30px;">Оберіть тренування для покупки</h2>
    <div v-for="(index, key) in getUsers.buyedList" :key="key">
      <div v-if="key === '0'">
        <div v-for="(indexs, keys) in index" :key="keys">
          <div v-if="!indexs && keys === '0'" class="programCenter">
            <router-link to="/resultbuy?vds=nabirM&bodyType=0" style="text-decoration:none" class="programTitle">Набір чоловік мала вага</router-link>
          </div> <!-- NABIR M  -->
          <div v-if="!indexs && keys === '1'" class="programCenter">
            <router-link to="/resultbuy?vds=nabirM&bodyType=1" style="text-decoration:none" class="programTitle">Набір чоловік середня вага</router-link>
          </div> <!-- NABIR M  -->
        </div>
      </div>
      <div v-if="key === '1'">
        <div v-for="(indexs, keys) in index" :key="keys">
          <div v-if="!indexs && keys === '1'" class="programCenter">
            <router-link to="/resultbuy?vds=tonusM&bodyType=1" style="text-decoration:none" class="programTitle">Тонус чоловік середня вага</router-link>
          </div> <!-- TONUS M  -->
          <div v-if="!indexs && keys === '2'" class="programCenter">
            <router-link to="/resultbuy?vds=tonusM&bodyType=2" style="text-decoration:none" class="programTitle">Тонус чоловік велика вага</router-link>
          </div> <!-- TONUS M  -->
        </div>
      </div>
      <div v-if="key === '2'">
        <div v-for="(indexs, keys) in index" :key="keys">
          <div v-if="!indexs && keys === '2'" class="programCenter">
            <router-link to="/resultbuy?vds=shudnenaM&bodyType=2" style="text-decoration:none" class="programTitle">Схуднення чоловік велика вага</router-link>
          </div> <!-- SLIM M  -->
        </div>
      </div>
      <div v-if="key === '10'">
        <div v-for="(indexs, keys) in index" :key="keys">
          <div v-if="!indexs && keys === '0'" class="programCenter">
            <router-link to="/resultbuy?vds=tonusG&bodyType=0" style="text-decoration:none" class="programTitle">Тонус дівчина мала вага</router-link>
          </div> <!-- TONUS F  -->
          <div v-if="!indexs && keys === '1'" class="programCenter">
            <router-link to="/resultbuy?vds=tonusG&bodyType=1" style="text-decoration:none" class="programTitle">Тонус дівчина середня вага</router-link>
          </div> <!-- TONUS F  -->
          <div v-if="!indexs && keys === '2'" class="programCenter">
            <router-link to="/resultbuy?vds=tonusG&bodyType=2" style="text-decoration:none" class="programTitle">Тонус дівчина велика вага</router-link>
          </div> <!-- TONUS F  -->
        </div>
      </div>
      <div v-if="key === '11'">
        <div v-for="(indexs, keys) in index" :key="keys">
          <div v-if="!indexs && keys === '2'" class="programCenter">
            <router-link to="/resultbuy?vds=shudnenaG&bodyType=2" style="text-decoration:none" class="programTitle">Схуднення дівчина велика вага</router-link>
          </div> <!-- SLIM F  -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Result from "./result";

export default {
  name: "trenerovki",
  components: {Result},
  computed: {
    ...mapGetters(["getUsers"])
  },
  methods: {
    ...mapActions(["getSessionInfo"])
  },
  created() {
    this.getSessionInfo()
  }
}
</script>

<style scoped>

</style>
