<template>
  <div>
    <div class="result" style="flex-direction:column">
      <div class="resultBlock">
        <div class="resultBlockBorder loginForm">
          <div class="login">
            <label>
              <input type="number" v-model="olataData" @change="() => { if(olataData > 2000 || olataData < 200) { this.olataData = 200 }}"
                     step="1" placeholder="До сплати" required>
            </label>
          </div>
          <div @click="submit" class="oneBlockButton" >ПРИДБАТИ</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "oplata",
  data() {
    return{
      olataData: ''
    }
  },
  computed: {
    ...mapGetters(['getUsers'])
  },
  methods: {
    ...mapActions(['getOplata', 'getSessionInfo']),
    submit() {
      let cityIf = 0
      if(this.getUsers.user.city === 'КРОПИВНИЦКИЙ'){
        cityIf = 2
      }
      else if(this.getUsers.user.city === 'СМЕЛА'){
        cityIf = 0
      }
      else if(this.getUsers.user.city === 'ЧЕРКАССЫ'){
        cityIf = 1
      }
      else if(this.getUsers.user.city === 'ХМЕЛЬНИЦКИЙ'){
        cityIf = 3
      }
      this.getOplata(
          {
            oplata: this.olataData,
            city: cityIf,
            packId: this.$route.query.packId,
            bodyType: this.$route.query.bodyType
          })
    }
  },
  created() {
    this.getSessionInfo()
  }
}
</script>

<style lang="scss" scoped>
.result{
  display:flex;
  align-content:center;
  align-items:center;
  align-self:center;
  justify-content:center;

  height:100vh
}
.resultBlock{
  padding:20px;
  background: rgba(0, 96, 131, 0.2);
  backdrop-filter: blur(25px);
  border-radius: 20px;
}
.resultBlockBorder{
  background: rgba(0, 96, 131, 0.2);
  /* light blue */

  border: 3px solid #49A7C9;
  box-sizing: border-box;
  backdrop-filter: blur(25px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 20px;
  padding:20px;
}
.resultCurs{
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 37px;
  text-align: center;

  color: #C8ECFF;
}
.resultCursType{
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 37px;
  text-align: center;

  color: #F8F8F8;
}

img{
  display: flex;
  margin: 0 auto;
}

.loginForm{
  display:flex;
  flex-direction:column;
  align-content:center;
  align-items:center;
  align-self:center;
  justify-content:center;
  justify-items:center;
  justify-self:center;
  margin: 0 auto;

  .login{
    line-height: 10px;
    display:flex;

    label{
      input{
        background: #888888;

        border-radius: 40px;
        border:none;

        width:245px;

        margin: 5px;

        padding:10px;

        color:#DBDADA;

        &::placeholder{
          color:#DBDADA;
        }
      }
    }
  }
  .loginSelect{
    line-height: 10px;
    display:flex;

    label{
      select{
        background: #888888;

        border-radius: 40px;
        border:none;

        width:245px;

        margin:5px;
        padding:10px;

        color:#DBDADA;

      }
    }
  }
  .loginButton{
    width:70%;
    margin: 0 auto;
    height:50px;
    margin-top:30px;
    margin-bottom:50px;
    margin-left: 30px;
  }
}

</style>
