<template>
  <div>
    <div style="display:flex; align-items:center; align-self:center; align-content:center; height:90vh">
      <div class="blurBlock">
        <div class="blurBlockPadding">
          <div class="blurBlockPaddingText">
            <h6 style="margin-bottom:30px">Увійти в профіль</h6>
            <div class="loginForm">
              <div class="loginEmail">
                <label>
                  <input v-model="email" placeholder="E-mail або номер телефону" type="text"/>
                </label>
              </div>
              <div class="loginSelect">
                <label>
                  <input v-model="pass" placeholder="Пароль" type="password">
                </label>
              </div>
              <button class="loginButton oneBlockButton" @click="loginAccount()">Увійти</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: "login",
  data() {
    return{
      email: null,
      pass: null
    }
  },
  methods: {
    ...mapActions(['loginAccountActions', 'getSessionInfo']),
    loginAccount: async function (){
      await this.loginAccountActions({"login": this.email, "password": this.pass})
      await this.getSessionInfo()
    }
  },
}
</script>

<style lang="scss" scoped>
.loginForm{
  display:flex;
  flex-direction:column;
  align-content:center;
  justify-content:center;
  width:280px;
  margin: 0 auto;

  .loginEmail{
    margin-top:-20px;
    line-height: 10px;
    display:flex;

    label{
      input{
        background: #888888;

        border-radius: 40px;
        border:none;

        width:260px;

        margin:5px;
        margin-top:20px;

        padding:10px;

        color:#FFFFFF;
        &::placeholder{
          color: #f8f7f7;
        }
      }
    }
  }
  .loginSelect{
    line-height: 10px;
    display:flex;

    label{
      input{
        background: #888888;

        border-radius: 40px;
        border:none;

        width:260px;

        margin:5px;
        margin-top:20px;

        padding:10px;

        color:#FFFFFF;
        &::placeholder{
          color: #f8f7f7;
        }
      }
    }
  }
  .loginButton{
    width:70%;
    margin: 0 auto;
    height:50px;
    margin-top:30px;
    margin-bottom:50px;
  }
}
</style>
