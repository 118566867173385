<template xmlns="http://www.w3.org/1999/html">
  <div>
    <div class="blurBlock" style="margin-top:30px">
      <div class="blurBlockPadding">
        <div>
          <p style="text-align:center; padding: 30px 60px; color:#FFFFFF; font-family: Roboto,sans-serif; font-weight: 500; font-size: 22px; z-index:1000">
            Загальні положення<br/>
            З метою підвищення якості обслуговування та безпеки зберігання даних наших користувачів, інформуємо вас про те, які саме дані ми збираємо і яким чином їх використовуємо на нашому сайті spartaclub (далі – сайт). Ми з повагою ставимось до конфіденційної інформації будь-якої особи, яка відвідує наш сайт, тому на підставі вимог чинного законодавства України та регламенту Європейського парламенту і ради нами була підготовлена дана Політика конфіденційності (далі – Політика).<br/>
            <br/>
            Повідомляємо, що ми застосовуємо файли сookie. Із кожним вашим відвідуванням нашого сайту ми можемо збирати інформацію, що стосується пристроїв, які ви використовуєте, та мереж, до яких ви приєднуєтесь, коли користуєтеся нашими сервісами. Це може включати таку інформацію, як: IP-адреса, інформація для входу в обліковий запис, тип браузера та версія, тип та вид плагінів браузера, операційна система та платформа, інформація про ваші відвідування, у тому числі, маршрут переміщення за URL-адресами на, через чи з нашого сайту, продукти, які ви переглядали або шукали, помилки завантаження, довжина відвідування певних сторінок тощо. Ми збираємо таку інформацію шляхом використання різних технологій, у тому числі, cookie. Файли сookie – це невеликі блоки даних, які розміщуються на тимчасове зберігання на жорсткий диск вашого комп’ютера або у мобільний пристрій для того, щоб ви могли більш ефективно користуватися нашим сайтом. Якщо ви надаєте згоду, то це дозволяє нам використовувати файли cookie кожного разу, коли ви користуєтесь нашим сайтом.<br/>

            Перелік персональних даних<br/>
            Перелік персональних даних користувачів, які можуть бути зібрані, збережені та використані під час роботи з сайтом:<br/>

            - ваші прізвище, ім’я та по батькові, ідентифікаційний номер, адреса, номер телефону, електронна адреса або інші контактні дані;<br/>
            - інформація про ваш комп'ютер, включаючи IP-адресу, географічне розташування, тип і версію браузера і операційну систему;<br/>
            - інформація про ваші відвідування і використання сайту, включаючи тривалість відвідування, перегляди сторінок і шляхи навігації по сайту тощо;<br/>
            - інформація, яку ви вводите при використанні послуг на нашому сайті;<br/>
            - інформація про проведення розрахункових операцій, банківських рахунків тощо;<br/>
            - інформація, що міститься в будь-яких повідомленнях, які ви відправляєте нам по електронній пошті або через наш сайт, включаючи його комунікаційний контент і метадані;<br/>
            - будь-яку іншу особисту інформацію, яку ви нам надсилаєте та вносите під час реєстрації на нашому сайті.<br/>

            Цілі опрацювання персональних даних<br/>
            Персональні дані, надані вами через сайт, будуть використовуватись для наступних цілей:<br/>

            - адміністрування нашого сайту і бізнесу;<br/>
            - персоналізація нашого сайту для вас, дозволяючи використовувати наші послуги на нашому сайті;<br/>


            Доступ до ваших персональних даних<br/>
            Ми вправі розкрити вашу особисту інформацію у виключних випадках  тільки для цілей, викладених у цій Політиці, будь-якому з наших співробітників, посадових осіб, страховиків, професійних консультантів, агентів, постачальників, а також газорозподільній та (або) газопостачальній компанії тощо.<br/>

            Повідомляємо, що надані вами відомості включені до баз персональних даних, власником яких є ТОВ «104.ЮА» з метою їх обробки, для ведення обліку в межах, передбачених законодавством. Для виконання вищезазначеної мети ваші дані можуть надаватись третім особам, безпосередньо задіяним в обробці цих даних.<br/>

            Персональні дані можуть бути передані третім особам у наступних випадках:<br/>

            - надання вами однозначної згоди на таку передачу;<br/>
            - необхідності укладення чи виконання правочину між нами та третьою особою на вашу користь;<br/>
            - необхідності для формування та здійснення правових процедур (претензії, позови, судові процеси тощо);<br/>
            - за наявності важливих підстав суспільного інтересу;<br/>
            - за обґрунтованими запитами державних органів, які мають право вимагати й одержувати такі дані та інформацію.<br/>

            За винятком випадків, передбачених у цій Політиці, ми зобов’язуємося не надавати вашу особисту інформацію третім особам.<br/>

            Період зберігання персональних даних<br/>
            Ваша згода на опрацювання персональних даних діє протягом невизначеного строку. Строк зберігання ваших персональних даних не обмежений. Ваші персональні дані обробляються у строк, не більший ніж це необхідно відповідно до їх законного призначення та цілей, визначених цією Політикою.<br/>
            <br/>

            Ваші права<br/>
            При наданні нам ваших персональних даних для опрацювання, у вас є наступні права:<br/>

            - ви можете вимагати доступ до ваших персональних даних;<br/>
            - ви можете вимагати виправлення будь-яких невірних персональних даних про вас;<br/>
            - ви маєте право вимагати видалення ваших персональних даних;<br/>
            - ви маєте право на обмеження та заперечення проти опрацювання ваших персональних даних, а також право на мобільність даних;<br/>
            - ви маєте право в будь-який момент на відкликання вашої згоди на опрацювання персональних даних.<br/>

            Але звертаємо вашу увагу, що робота деяких можливостей сайту, для яких необхідна наявність ваших персональних даних, може бути припинена з моменту їх видалення.<br/>

            Цей сайт не призначений та не розроблений для дітей віком до 14 років. Опрацювання персональних даних неповнолітніх осіб, у віці від 14 до 18 років здійснюється виключно за наявності згоди батьків (усиновлювачів) або піклувальників.<br/>

            Якщо у вас є скарга щодо будь-якого опрацювання ваших персональних даних, ви можете звернутися до нас, до Уповноваженого Верховної Ради України з прав людини або до суду.<br/>

            Безпека даних<br/>
            Повідомляємо вам, що для захисту ваших персональних даних вжито всіх необхідних заходів від несанкціонованого доступу, зміни, розкриття чи знищення із застосуванням відповідних технічних і організаційних інструментів.<br/>

            Ми використовуємо технологію шифрування даних за технологією HTTPS від вашого браузера до сайту spartaclub.net<br/>

            На жаль, необхідно визнати, що передача інформації через інтернет не завжди є повністю безпечною. Тому, як тільки ми отримаємо вашу інформацію, ми зробимо все можливе для захисту ваших персональних даних.<br/>

            Ви несете відповідальність за те, щоб пароль, який використовується для доступу до нашого сайту, був конфіденційним. Ми не будемо запитувати у вас пароль (крім випадків, коли ви входите на наш сайт).<br/>

            Зміни до умов Політики<br/>
            На сайті можуть змінитись умови Політики. У такому разі необхідно періодично переглядати сайт. Всі зміни набувають чинності з моменту їх публікації. Продовжуючи, використовувати сайт, ви підтверджує згоду з новими умовами Політики у редакції, чинній на момент використання сайту.<br/>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import buttonBlock from "../comp/buttonBlock/index";
import navMenu from "../comp/navMenu/index";

export default {
  name: "confidentiality",
}
</script>

<style scoped>

</style>
