<template>
  <div>
    <router-link v-if="!getUsers.user.isBuyed" class="selectProfileFalse" to="/login">
      Увійти
    </router-link>
    <div v-else class="activeProfile">
      <router-link style="margin-left:10px; margin-right:10px" to="/profile" class="name">
        <p>
          {{getUsers.user.firstName + ' ' + getUsers.user.lastName}}
        </p>
        <img :src="getUsers.avatar" style="width:28px; margin: 5px; border-radius: 25px;">
      </router-link>
      <div class="selectLine"></div>
      <div class="selectProfile">
        <router-link to="/workout" class="detal">
          Мої тренування
        </router-link>
        <div style="width: 100%; height: 1px; background: linear-gradient(90deg, rgba(73, 167, 201, 0) 0%, rgba(73, 167, 201, 0.2) 50.52%, rgba(73, 167, 201, 0) 100%);"></div>
        <router-link to="/trenirovki" class="detal">
          Купити тренування
        </router-link>
        <div style="width: 100%; height: 1px; background: linear-gradient(90deg, rgba(73, 167, 201, 0) 0%, rgba(73, 167, 201, 0.2) 50.52%, rgba(73, 167, 201, 0) 100%);"></div>
        <router-link to="/profile" class="detal">
          Профіль
        </router-link>
        <div style="width: 100%; height: 1px; background: linear-gradient(90deg, rgba(73, 167, 201, 0) 0%, rgba(73, 167, 201, 0.2) 50.52%, rgba(73, 167, 201, 0) 100%);"></div>
        <div @click="logOut" class="detal" style="padding-bottom:8px">
          Вийти
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "index",
  methods: {
    ...mapActions(['logoutActions', 'getSessionInfo']),
    logOut: function() {
      this.logoutActions()
    }
  },
  computed: {
    ...mapGetters(['getUsers'])
  },
  created() {
    this.getSessionInfo()
  }
}
</script>

<style lang="scss" scoped>
.selectLine{
  height: 1px;
  background: #49A7C9;
  position:relative;
}
.selectProfileFalse{
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;

  text-decoration:none;
  /* identical to box height */

  text-align: right;

  /* light blue */

  color: #49A7C9;

}

.activeProfile{
  margin-top: 30px;
  background-color: rgba(#006083, 0.2);

  backdrop-filter: blur(25px);
  border-radius: 15px;


  .name{
    display: flex;
    align-content:center;
    align-self:center;
    align-items:center;
    justify-content:center;
    justify-self:center;
    justify-items:center;

    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;

    margin-top: -17px;
    /* identical to box height */

    color: #F8F8F8;

    cursor:pointer;

    text-decoration: none;

    svg{
      margin:10px;
      width:20px;
    }
  }

  .detal{
    display:flex;
    align-content:center;
    justify-content:center;

    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;

    color: #F8F8F8;

    margin:6px;

    cursor:pointer;

    text-decoration: none;

    &:last-child{
      margin-bottom:0;
    }
  }
}

@media screen and (max-width:1169px){
.activeProfile{
  margin-bottom: 30px;
  margin-top: 20px;
  .name{
    font-size:10px;
  }
}
  .selectProfileLine{
    width:110%;
  }


}
</style>
