<template>
  <div class="result">
    <div class="resultBlock">
      <div class="resultBlockBorder">
        <div v-for="(index, idx) in mass" :key="idx" style="display:flex; flex-direction:column; align-items:center; justify-items:center">
          <div v-if="vds === idx">
            <div>
              <img :src="index.img">
            </div>
            <div>
              <div class="resultCurs">
                {{ index.title }}
              </div>
              <div class="resultCursType">
                {{ index.context }}
              </div>
            </div>
            <div v-if="index.buttons" @click="routerLink(index.packId)" class="oneBlockButton">ПРИДБАТИ</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import men from "../../assets/img/resultMen.png";
import girl from "../../assets/img/resultGirl.png";

export default {
  name: "resultBuy",
  data(){
    return{
      vds: 'shudnenaM',

      mass: {
        shudnenaM: {
          img: men,
          title: 'Курс тренувань',
          context: '“Схуднення М”',
          buttons: true,
          packId: 2
        },
        tonusM:  {
          img: men,
          title: 'Курс тренувань',
          context: '“Тонус М”',
          buttons: true,
          packId: 1
        },
        nabirM:  {
          img: men,
          title: 'Курс тренувань',
          context: '“Набір М”',
          buttons: true,
          packId: 0
        },

        tonusG:  {
          img: girl,
          title: 'Курс тренувань',
          context: '“Тонус”',
          buttons: true,
          packId: 10
        },
        shudnenaG:  {
          img: girl,
          title: 'Курс тренувань',
          context: '“Схуднення”',
          buttons: true,
          packId: 11
        },

        defM: {
          img: men,
          title: 'Радимо вам звернутись до',
          context: 'персонального тренера',
          buttons: false
        },
        defG: {
          img: girl,
          title: 'Радимо вам звернутись до',
          context: 'персонального тренера',
          buttons: false
        }
      }
    }
  },
  computed: {

  },
  methods: {
    routerLink(data) {
      this.$router.push('/oplata?packId=' + data + '&bodyType=' + this.$route.query.bodyType)
    }
  },
  created() {
    this.vds = this.$route.query.vds
    window.scrollTo(0,0);
  }
}
</script>

<style lang="scss" scoped>
.result{
  display:flex;
  align-content:center;
  align-items:center;
  align-self:center;
  justify-content:center;
}
.resultBlock{
  padding:20px;
  background: rgba(0, 96, 131, 0.2);
  backdrop-filter: blur(25px);
  border-radius: 20px;
}
.resultBlockBorder{
  background: rgba(0, 96, 131, 0.2);
  /* light blue */

  border: 3px solid #49A7C9;
  box-sizing: border-box;
  backdrop-filter: blur(25px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 20px;
  padding:20px;
}
.resultCurs{
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  line-height: 37px;
  text-align: center;

  color: #C8ECFF;
}
.resultCursType{
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 37px;
  text-align: center;

  color: #F8F8F8;
}

img{
  display: flex;
  margin: 0 auto;
}
</style>
